'use strict';

var focusHelper = require('../components/focus');
var scrollAnimate = require('../components/scrollAnimate');
var wishlistHelper = require('./wishlist');

var paymentMethods = {
    CREDIT_CARD: '02',
    PAI_LOAN: '05',
    MYBOX: '09',
    CPC: '08',
    QUIX: '11'
};

var financingTypes = {
    INSTALLMENT: 'Tarjeta',
    MYBOX: 'Suscripcion',
    CPC: 'CPC',
    QUIX: 'QUIX'
};

var selectedPayment = null;

/**
 * Formats a quantity to a money format
 * @param {float} number - The quantity to be formated
 * @param {string} currencySymbolParam - The currency symbol
 * @param {int} decPlacesParam - The decimal places
 * @param {string} decSepParam - The decimal separator
 * @param {string} thouSepParam - The thousands places
 * @returns {string} The quantity formatted
 */
function formatMoney(number, currencySymbolParam, decPlacesParam, decSepParam, thouSepParam) {
    var currencySymbol = (typeof currencySymbolParam === 'undefined') ? '€' : currencySymbolParam;
    var decPlaces = (typeof decPlacesParam === 'undefined') ? 2 : decPlacesParam;
    var decSep = (typeof decSepParam === 'undefined') ? ',' : decSepParam;
    var thouSep = (typeof thouSepParam === 'undefined') ? '.' : thouSepParam;
    var sign = number < 0 ? '-' : '';
    var fixedNumber = Math.abs(Number(number) || 0).toFixed(decPlaces);
    var i = String(parseInt(fixedNumber, 10));
    var j = i.length;
    j = j > 3 ? j % 3 : 0;

    var formatted = sign
        + (j ? i.substr(0, j) + thouSep : '')
        + i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, '$1' + thouSep)
        + (decPlaces ? decSep + Math.abs(fixedNumber - i).toFixed(decPlaces).slice(2) : '');

    return formatted + ' ' + currencySymbol;
}

/**
 * DEPRECATED. Now is used the updateQuote method instead the recalculateQuote method
 * Utility function: rounds the quota.
 *
 * @param {number} quota The number to be rounded
 * @returns {number} The quota rounded
 */
/*
function roundQuota(quota) {
    var result = 0;

    var numThousand = quota * 1000.0;
    var intThousand = Math.floor(numThousand);
    var numHundred = quota * 100.0;
    var intHundred = Math.floor(numHundred);
    var decCut = intThousand - (intHundred * 10);
    if (decCut < 5) {
        result = (intHundred * 1.0) / 100.0;
    } else {
        result = ((intHundred + 1) * 1.0) / 100.0;
    }

    return result;
}
*/

/**
 * Calculates the final financed import using the french method
 * @param {number} price The quantity to be financed
 * @param {number} quotas The number of quotas
 * @param {number} interest The interest to be applied
 * @returns {number} The calculated final import
 */
function getTotalFinanced(price, quotas, interest) {
    var totalFinanced = price;

    // Will apply the calculation if interest is greater than 0. If not, we return the price
    if (interest > 0.0) {
        // TODO: implement real formula
        totalFinanced = (parseFloat(price) + parseFloat(price) * (interest / 100));
    }

    return totalFinanced;
}

/**
 * Updates product financing price and legal informations
 * based on the currently selected quota
 *
 * @returns {void}
 */
function updateFinancingInformation() {
    var $legalInformation = $('[data-js="financed-payment-conditions"], '
        + '[data-js="subscription-payment-conditions"]');
    var $productPriceInformation = $('[data-js="financed-payment-product-price"], [data-js="subscription-payment-product-price"]');
    var $selectedQuota = $('[data-js="btn-payment"]').filter('.selected');

    var priceContent = parseFloat($selectedQuota.find('[content]').attr('content'));
    var totalListQuota = parseFloat($selectedQuota.find('[data-quota]').attr('data-totallistquota'));
    var salesQuote = parseFloat($selectedQuota.find('[data-quota]').attr('data-origsalesprice'));
    var listQuote = parseFloat(($selectedQuota.find('[data-quota]').attr('data-quotalistprice')));
    var totalPrice = parseFloat($selectedQuota.find('[data-quota]').attr('data-totalprice'));
    var totalListPrice = parseFloat($selectedQuota.find('[data-quota]').attr('data-totallistprice'));
    var totalCashPrice = parseFloat($selectedQuota.find('[data-quota]').attr('data-referenceprice'));
    var totalListCashPrice = parseFloat($selectedQuota.find('[data-quota]').attr('data-referencelistprice'));

    if ($selectedQuota.length > 0) {
        $legalInformation.find('span[id="var0"]').html(formatMoney(totalPrice));
        $legalInformation.find('span[id="var1"]').html($selectedQuota.find('[data-quota]').attr('data-tin'));
        $legalInformation.find('span[id="var2"]').html($selectedQuota.find('[data-quota]').attr('data-tae'));
        $legalInformation.find('span[id="var3"]').html($selectedQuota.find('[data-quota]').attr('data-quota'));
        $legalInformation.find('span[id="var4"]').html($('[data-js="total-price"]').html());
        $legalInformation.find('span[id="var6"]').html(formatMoney(priceContent));
        $legalInformation.find('span[id="var8"]').html(formatMoney(totalPrice - totalCashPrice));

        $productPriceInformation.find('span[id="var0"]').html($('[data-js="total-price"]').html());
        $productPriceInformation.find('span[id="var1"]').html($selectedQuota.find('[data-quota]').attr('data-tin'));
        $productPriceInformation.find('span[id="var2"]').html($selectedQuota.find('[data-quota]').attr('data-tae'));
        if ((salesQuote === priceContent) && (listQuote !== priceContent)) {
            $productPriceInformation.find('span[id="var6-strike"]')
                .removeClass('d-none')
                .html(formatMoney(listQuote));
        } else if ((salesQuote === priceContent) && (listQuote === priceContent)) {
            $productPriceInformation.find('span[id="var6-strike"]')
                .addClass('d-none');
        } else if ((salesQuote !== priceContent) && (listQuote !== priceContent) && (listQuote !== salesQuote)) {
            $productPriceInformation.find('span[id="var6-strike"]')
                .removeClass('d-none')
                .html(formatMoney(totalListQuota));
        }
        $productPriceInformation.find('span[id="var6"]').html(formatMoney(priceContent));
        if ((salesQuote === priceContent) && (totalListPrice !== totalPrice)) {
            $productPriceInformation.find('span[id="var8-strike"]')
                .removeClass('d-none')
                .html(formatMoney(totalListPrice));
        } else if ((salesQuote === priceContent) && (totalListPrice === totalPrice)) {
            $productPriceInformation.find('span[id="var8-strike"]')
                .addClass('d-none');
        } else if ((salesQuote !== priceContent) && (totalListPrice !== totalPrice)) {
            $productPriceInformation.find('span[id="var8-strike"]')
                .removeClass('d-none')
                .html(formatMoney(totalListPrice));
        }
        $productPriceInformation.find('span[id="var8"]').html(formatMoney(totalPrice));

        if (totalCashPrice !== totalPrice) {
            $productPriceInformation.find('span[id="var9-label"]')
                .removeClass('d-none');
            $productPriceInformation.find('span[id="var9"]')
                .removeClass('d-none')
                .html(formatMoney(totalCashPrice));
            $productPriceInformation.find('span[id="var8-label"]')
                .addClass('d-none');
            $productPriceInformation.find('span[id="var8-label2"]')
                .removeClass('d-none');
            if (totalCashPrice !== totalListCashPrice) {
                $productPriceInformation.find('span[id="var9-strike"]')
                    .removeClass('d-none')
                    .html(formatMoney(totalListCashPrice));
            } else {
                $productPriceInformation.find('span[id="var9-strike"]')
                    .removeClass('d-none')
                    .addClass('d-none');
            }
        } else {
            $productPriceInformation.find('span[id="var8-label2"]')
                .addClass('d-none');
            $productPriceInformation.find('span[id="var8-label"]')
                .removeClass('d-none');
            $productPriceInformation.find('span[id="var9-label"]')
                .addClass('d-none');
            $productPriceInformation.find('span[id="var9"]')
                .removeClass('d-none')
                .addClass('d-none');
            $productPriceInformation.find('span[id="var9-strike"]')
                .removeClass('d-none')
                .addClass('d-none');
        }

        var lastQuota = parseFloat($selectedQuota.find('[data-quota]').attr('data-lastquota'));
        var numQuotes = $selectedQuota.find('[data-quota]').attr('data-quota');
        if (lastQuota !== priceContent) {
            $legalInformation.find('span[id="var5"]').html(numQuotes - 1);
            $legalInformation.find('span[id="var7"]').html(formatMoney(lastQuota));
            $productPriceInformation.find('span[id="var5"]').html(numQuotes - 1);
            $productPriceInformation.find('span[id="lastQuoteText"]')
                .attr('class', '')
                .find('span[id="var7"]').html(formatMoney(lastQuota));
        } else {
            // For legal information we assume that will always show the last quote
            $legalInformation.find('span[id="var5"]').html(numQuotes - 1);
            $legalInformation.find('span[id="var7"]').html(formatMoney(priceContent));
            $productPriceInformation.find('span[id="var5"]').html(numQuotes);
            $productPriceInformation.find('span[id="lastQuoteText"]')
                .attr('class', 'd-none');
        }
    }
}

/**
 * Retrieves the selected financing data for a set complement product
 * @param {jquery} element - DOM container for a given complement
 * @return {string} - financing data to be used when adding product to cart
 */
function getFinanceData(element) {
    var returnObject = {
        selectedQuote: '',
        paymentMethod: paymentMethods.CREDIT_CARD
    };
    var selectedQuote = null;
    var $element;
    if ($(element).hasClass('product-insurance')) {
        $element = $(element).find('[data-js="checkbox-addAdditionalProduct"]').filter(function () {
            return $(this).prop('checked') === true;
        }).closest('.row');
    } else {
        $element = $(element);
    }
    var setProductFinanced = $element.find('[data-js="price-financed"],[data-js="price-installment"],[data-js="price-mybox"]');
    if (setProductFinanced) {
        setProductFinanced.each(function () {
            if (!$(this).hasClass('d-none')) {
                selectedQuote = {
                    quotas: parseInt($(this).attr('data-quota'), 10),
                    tin: $(this).attr('data-tin'),
                    tae: $(this).attr('data-tae'),
                    financingType: $(this).attr('data-financingtype')
                };
            }
        });
    }

    if (selectedQuote !== null) {
        returnObject.selectedQuote = JSON.stringify(selectedQuote);
        if (selectedQuote.financingType === financingTypes.INSTALLMENT) {
            returnObject.paymentMethod = paymentMethods.CREDIT_CARD;
        } else if (selectedQuote.financingType === financingTypes.MYBOX) {
            returnObject.paymentMethod = paymentMethods.MYBOX;
        } else if (selectedQuote.financingType === financingTypes.CPC) {
            returnObject.paymentMethod = paymentMethods.CPC;
        } else if (selectedQuote.financingType === financingTypes.QUIX) {
            returnObject.paymentMethod = paymentMethods.QUIX;
        } else {
            returnObject.paymentMethod = paymentMethods.PAI_LOAN;
        }
    }

    return returnObject;
}

/**
 * Compares the financing types for two quotes
 * @param {string} elementFinType - The financing type coming from a jQuery element
 * @param {Object} paymentObjFinType - The financing type coming from an Object
 * @returns {boolean} The result of the comparison
 */
function isSameFinancingType(elementFinType, paymentObjFinType) {
    var sameFinancingType = false;

    if (paymentObjFinType === financingTypes.INSTALLMENT 
        || paymentObjFinType === financingTypes.MYBOX 
        || paymentObjFinType === financingTypes.CPC
        || paymentObjFinType === financingTypes.QUIX) {
        if (elementFinType === paymentObjFinType) {
            sameFinancingType = true;
        } else {
            sameFinancingType = false;
        }
    } else {
        sameFinancingType = elementFinType !== financingTypes.INSTALLMENT && elementFinType !== financingTypes.MYBOX;
    }

    return sameFinancingType;
}

/**
 * Compares two stringified selected quotes
 * @param {string} quote1 - The first selected quote to compare
 * @param {string} quote2 - The second selected quote to compare
 * @return {boolean} - true if both are equivalent.
 */
function equivalentQuotes(quote1, quote2) {
    if (quote1 === '' && quote2 === '') {
        return true;
    }

    if (quote1 === '') {
        return false;
    }

    if (quote2 === '') {
        return false;
    }

    var parsed1 = JSON.parse(quote1);
    var parsed2 = JSON.parse(quote2);

    var sameFinancingType = isSameFinancingType(parsed2.financingType, parsed1.financingType);

    return (parsed1.quotas === parsed2.quotas
        && Number(parsed1.tin) === Number(parsed2.tin)
        && Number(parsed1.tae) === Number(parsed2.tae)
        && sameFinancingType === true
    );
}

/**
 * Retrieves the selected financing data for the main product
 * @param {jquery} element - DOM container for a given add to cart button
 * @return {string} - financing data to be used when adding product to cart
 */
function getMainFinanceData(element) {
    var returnObject = {
        selectedQuote: '',
        paymentMethod: paymentMethods.CREDIT_CARD
    };
    var selectedQuote = null;
    var mainProductFinanced = $(element).find('[data-js="btn-payment"]');
    if (mainProductFinanced) {
        mainProductFinanced.each(function () {
            if ($(this).hasClass('selected')) {
                var dataElement = $(this).find('[data-js="total-price-quota"]')[0];
                selectedQuote = {
                    quotas: parseInt($(dataElement).attr('data-quota'), 10),
                    tin: $(dataElement).attr('data-tin-value'),
                    tae: $(dataElement).attr('data-tae-value'),
                    financingType: $(dataElement).attr('data-financingtype')
                };
            }
        });
    }

    if (selectedQuote !== null) {
        returnObject.selectedQuote = JSON.stringify(selectedQuote);
        if (selectedQuote.financingType === financingTypes.INSTALLMENT) {
            returnObject.paymentMethod = paymentMethods.CREDIT_CARD;
        } else if (selectedQuote.financingType === financingTypes.MYBOX) {
            returnObject.paymentMethod = paymentMethods.MYBOX;
        } else if (selectedQuote.financingType === financingTypes.CPC) {
            returnObject.paymentMethod = paymentMethods.CPC;
        } else if (selectedQuote.financingType === financingTypes.QUIX) {
            returnObject.paymentMethod = paymentMethods.QUIX;
        } else {
            returnObject.paymentMethod = paymentMethods.PAI_LOAN;
        }
    }

    return returnObject;
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length) {
        pid = $('.product-set-detail').data('pid');
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    return $el && $('.set-items').length
        ? $($el).closest('.product-detail').find('.quantity-select')
        : $('.quantity-select');
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} product - Product object
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(product, attr, $productContainer, msgs) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="'
            + attrValue.value + '"]');
        var $selectAttrValue = $productContainer.find('span.selectedSwatchedValue');

        if (attrValue.selectable) {
            $attrValue
                .removeClass('unselectable')
                .addClass('selectable')
                .attr('value', attrValue.url)
                .removeAttr('disabled');
        } else {
            $attrValue
                .removeClass('selectable')
                .addClass('unselectable')
                .attr('disabled', true);
        }

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
            $selectAttrValue.text(attrValue.displayValue);
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        $attrValue.attr('data-url', attrValue.url);
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {number} attrLength - Number of attributes
 */
function processNonSwatchValues(attr, $productContainer, attrLength) {
    // Disable the first option
    var attrID = attr.id.replace(/\s/g, '');
    var $attr = '[data-attr="' + attrID + '"]';
    var $selectOption = $productContainer.find($attr + ' .select-' + attrID);
    var $defaultOption = $productContainer.find($attr + ' .select-' + attrID + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);
    // $defaultOption.attr('disabled', true);

    var attributesSection = $productContainer.find('div.attributes');
    var attrsHiddenInput = $(attributesSection).find('input[class="attributes-data"]');
    var isDefaultSelected = $(attrsHiddenInput).attr('data-defaultselected');

    if (attrLength > 2 && isDefaultSelected === 'true') {
        $defaultOption.attr('disabled', false);
        $defaultOption.attr('selected', 'selected');
    }

    var attrSelected = false;

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');

        if (attrValue.selectable) {
            $attrValue.attr('value', attrValue.url).removeAttr('disabled');
        } else {
            $attrValue.attr('disabled', true);
        }

        if ((isDefaultSelected === 'false' && $attrValue.attr('data-default') === 'true') || attrValue.selected) {
            $attrValue.attr('selected', 'selected');
            attrSelected = true;
        } else {
            $attrValue.removeAttr('selected');
        }
    });

    if (!attrSelected) {
        $defaultOption.attr('selected', 'selected');
        $selectOption.val($defaultOption.val());
    }
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} product - Product object
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(product, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];
    var attrs = product.variationAttributes;

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id.toLowerCase()) > -1) {
            processSwatchValues(product, attr, $productContainer, msgs);
        } else {
            processNonSwatchValues(attr, $productContainer, attrs.length);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li><div>' + message + '</div></li>';
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * @typedef UpdatedOptionValue
 * @type Object
 * @property {string} id - Option value ID for look up
 * @property {string} url - Updated option value selection URL
 */

/**
 * @typedef OptionSelectionResponse
 * @type Object
 * @property {string} priceHtml - Updated price HTML code
 * @property {Object} options - Updated Options
 * @property {string} options.id - Option ID
 * @property {UpdatedOptionValue[]} options.values - Option values
 */

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(optionsHtml, $productContainer) {
    // Update options
    $productContainer.find('.product-options').first().empty().html(optionsHtml);
}

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} bundleHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateBundles(product, $productContainer) {
    // Update options
    $productContainer
        .find(".product-variant-bundle")
        .first()
        .empty()
        .html(product.bundleHtml);

    if (product.bundledProducts){
        $(".add-to-cart").attr("data-mostimpid", product.id)
    }
}

/**
 * Resize height of carousel items for videos
 */
function resizeCarouselItemOfVideo() {
    if ($('.product-detail .carousel[id^=pdpCarousel] .carousel-video-inner-PDP').length) {
        const $carousel = $('.product-detail .carousel[id^=pdpCarousel]');

        if ($carousel.find('.carousel-item:not([class*=video])').length) {
            const $carouselItemWithPicture = $carousel.find('.carousel-item:not([class*=video])').first();
            const carouselItemWithPictureHeight = $carouselItemWithPicture.outerHeight();
            $carousel.find('.carousel-item.carousel-video-inner-PDP').outerHeight(carouselItemWithPictureHeight);
        }
    }
}

/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function createCarousel(imgs, isPartOfSet, $productContainer, productVideo) {
    var carousel = '';
    var carouselZoom = '';

    if (isPartOfSet) {
        var setImage = $productContainer.find('.product-item-image');
        $(setImage)
            .empty()
            .append(`<img src="${imgs[0].url}" 
                        class="img" 
                        alt="${(typeof imgs[0] !== 'undefined' && imgs[0] && typeof imgs[0].alt !== 'undefined') ? imgs[0].alt : ''}
                        image 
                        number ${imgs[0].index}" 
                        itemprop="image" />`
                    );
        
        // If is not the principal product of the Set product, we don't update the full carousel
        if ($productContainer.data('pid') != $('.col-product-detail .product-detail').first().data('pid')){
            return;
        }
        carousel = $('.product-detail .carousel-small .carousel').first();
        carouselZoom = $('.product-detail .modal-zoom-body .carousel').first();
    } else {
        carousel = $productContainer.find('.carousel-small .carousel').first();
        carouselZoom = $productContainer.find('.modal-zoom-body .carousel');
    }
    $(carousel).carousel('dispose');
    $(carouselZoom).carousel('dispose');
    var carouselId = $(carousel).attr('id');
    var carouselZoomId = $(carouselZoom).attr('id');
    var buttonPrevious = $('#buttonPrevious').val();
    var buttonNext = $('#buttonNext').val();
    var buttonExpand = $('#buttonExpand').val();
    var buttonCompress = $('#buttonCompress').val();
    
    // Create carousel modal
    $(carousel)
        .empty()
        .append(`<div class="row align-items-center">
                    <div class="col-1 d-flex">
                        <a class="carousel-control carousel-control-prev d-flex" href="${"#"}${carouselId}" role="button" data-slide="prev">
                            <span class="icon icon-dropdown-left c-dark f-14" aria-hidden="true"></span>
                            <span class="sr-only">${buttonPrevious}</span>
                        </a>
                    </div>
                    <div class="col-10">
                        <div class="carousel-inner" role="listbox" data-js="zoom-expand-PDP"></div>
                    </div>
                    <div class="col-1 d-flex">
                        <a class="carousel-control carousel-control-next d-flex" href="${"#"}${carouselId}" role="button" data-slide="next">
                            <span class="icon icon-dropdown-right c-dark f-14" aria-hidden="true"></span>
                            <span class="sr-only">${buttonNext}</span>
                        </a>
                    </div>
                </div>
                </div>
                 <div class="image-control d-flex">
                     <div class="image-carousel-control d-flex">
                         <ul class="carousel-indicators carousel-pagination center" aria-hidden="true"></ul>
                     </div>
                     <div class="zoom-icon d-none d-lg-flex">
                         <div class="nri-pdp">
                             <iscontentasset aid="NRI_code_PDP"/>
                         </div>
                         <span class="carousel-control icon icon-zoom-expand c-dark f-20" aria-hidden="true" role="button" data-js="zoom-expand-PDP"></span>
                         <span class="sr-only">${buttonExpand}</span>
                     </div>`                   
    );

    // Create Zoom Carousel modal
    $(carouselZoom)
        .empty()
        .append(`<div class="row align-items-center">
                    <div class="col-1">
                        <a class="carousel-control carousel-control-prev d-flex" href="${"#"}${carouselZoomId}" role="button" data-slide="prev">
                            <span class="icon icon-dropdown-left c-dark f-14" aria-hidden="true"></span>
                            <span class="sr-only">${buttonPrevious}</span>
                        </a>
                    </div>
                    <div class="col">
                        <div class="carousel-inner" role="listbox"></div>
                    </div>
                    <div class="col-1">
                        <a class="carousel-control carousel-control-next d-flex" href="${"#"}${carouselZoomId}" role="button" data-slide="next">
                            <span class="icon icon-dropdown-right c-dark f-14" aria-hidden="true"></span>
                            <span class="sr-only">${buttonNext}</span>
                        </a>
                    </div>
                    </div>
                    </div>
                 <div class="image-control d-flex">
                     <div class="image-carousel-control d-flex">
                         <ul class="carousel-indicators carousel-pagination center" aria-hidden="true"></ul>
                     </div>
                     <div class="zoom-icon d-none d-lg-flex">
                         <span class="carousel-control icon icon-zoom-compress c-dark f-20" aria-hidden="true" role="button" data-js="zoom-compress-PDP"></span>
                         <span class="sr-only">${buttonCompress}</span>
                     </div>`
                    
    );
        


    // eslint-disable-next-line no-plusplus
    for (var i = 0; i < imgs.length; i++) {
        // Carousel
        // eslint-disable-next-line max-len
        $('<div class="carousel-item" data-js="carousel-item-image"><img src="' + imgs[i].url + '" class="img-carousel" alt="' + imgs[i].alt + ' image number ' + parseInt(imgs[i].index, 10) + '" title="' + imgs[i].title + '" itemprop="image" /></div>').appendTo($(carousel).find('.carousel-inner'));
        $('<li data-target="#' + carouselId + '" data-slide-to="' + i + '" class=""></li>').appendTo($(carousel).find('.carousel-indicators'));

        // Carousel Zoom
        // eslint-disable-next-line max-len
        $('<div class="carousel-item modal-carousel-item-' + i + '" data-js="modal-carousel-items"><img src="' + imgs[i].url + '" class="img-carousel zoom-in" alt="' + imgs[i].alt + ' image number ' + parseInt(imgs[i].index, 10) + '" itemprop="image"  data-js="modal-zoom-image"/></div>').appendTo($(carouselZoom).find('.carousel-inner'));
        $('<li data-target="#' + carouselZoomId + '" data-slide-to="' + i + '" class=""></li>').appendTo($(carouselZoom).find('.carousel-indicators'));
    }

    //Add video of the variant to carousel
    var videoPath = '';
    var videoPreviewImg ='';
    var prdouctModalVideoDiv = $('#modal-video-PDP').find('[data-js="embed-product-video"]');

    if (productVideo && productVideo.hasVideo) {
        if(productVideo.isLocalVideo) {
            
            videoPath = $('#videoProductPath').val() + productVideo.videoPath;
            if (!productVideo.videoPreviewImage || productVideo.videoPreviewImage === 'null') {
                videoPath = videoPath + '#t=2';
            }
            videoPreviewImg = $('#videoProductPath').val() + productVideo.videoPreviewImage;

            prdouctModalVideoDiv.html('<video src="' + videoPath + '" controls></video>');
            prdouctModalVideoDiv.append(`<div class="play-button" data-js="playpause">
                                                <i class="play-triangle"></i>
                                            </div>`);
            $(`<div class="carousel-item carousel-video-inner-PDP">
                    <div class="image-container" data-js="video-expand-PDP">
                        <video src="${videoPath}"
                            poster="${videoPreviewImg}" 
                            class="img-carousel" 
                            alt="${(typeof imgs[0] !== 'undefined' && imgs[0] && typeof imgs[0].alt !== 'undefined') ? imgs[0].alt : ''} image number ${imgs.length.toFixed(0)}"
                            itemprop="video" 
                            playsinline>
                        </video>
                        <span class="play-button">
                            <i class="play-triangle"></i>
                        </span>
                    </div>
                </div>`
            ).appendTo('.carousel-inner');

            $($productContainer).trigger('initVideoEvent');
        } else {
            prdouctModalVideoDiv.html('<iframe src="https://www.youtube.com/embed/' + productVideo.videoPath + '"  allowfullscreen></iframe>');
            var videoPreviewImage = productVideo.videoPreviewImage ? productVideo.videoPreviewImageFullUrl : `https://img.youtube.com/vi/${productVideo.videoPath}/hqdefault.jpg`;
            $(`<div class="carousel-item carousel-video-inner-PDP">
                    <div class="image-container" data-js="video-expand-PDP">
                        <div class="iframe-div" id="video-container">
                            <img src="${videoPreviewImage}" 
                            alt="${(typeof imgs[0] !== 'undefined' && imgs[0] && typeof imgs[0].alt !== 'undefined') ? imgs[0].alt : ''} image number ${imgs.length.toFixed(0)}"
                                    data-video="${productVideo.videoPath}"
                                    itemprop="video" /> 
                            <span class="play-button">
                                <i class="play-triangle"></i>
                            </span>
                        </div>
                        <span id="iframe-overlay"></span>
                    </div>
                </div>` 
            ).appendTo('.carousel-inner');
            
        }
        var overlay = $productContainer.find('#iframe-overlay');
        if (window.innerWidth < 767) {
            overlay.addClass('d-none');
        }

        $('<li data-target="#' + carouselId + '" data-slide-to="' + i + '"></li>').appendTo($(carousel).find('.carousel-indicators'));
    }


    $($(carousel).find('.carousel-item')).first().addClass('active');
    $($(carousel).find('.carousel-indicators > li')).first().addClass('active');

    //If there's only one image don't show carousel indicators
    if (imgs.length === 1 && !productVideo.hasVideo) {
        $($(carousel).find('.image-carousel-control')).detach();
        $($(carouselZoom).find('.image-carousel-control')).detach();
    }
    $(carousel).carousel();
    $(carouselZoom).carousel();
    $($(carousel).find('.carousel-indicators')).attr('aria-hidden', true);
    resizeCarouselItemOfVideo();
}

/**
 * Updates the product Tag of the variant selected.	
 * @param {Object[]} tags - Array of tags values
 * @param {jQuery} $productContainer - DOM element for a given product
*/
function updateTags(tags, $productContainer){
    var customTags = require('../tags/tags');
    var tileHeaderLeft = $productContainer.find('.tile-header-left').first();
    var response ='';

    // The maximum number of tags displayed in the pdp is 3
    var maxTagsPDP = 3;
    var tagsToShow = Object.keys(tags).length > maxTagsPDP ? maxTagsPDP : Object.keys(tags).length;
    
    // Make the structure for each tag
    for (var i = 0; i < tagsToShow; i++){
        response += `<div class="ribbon ${tags[i].class || 'personalizeTags'}" 
                        data-js="product-tag" 
                        data-background="${tags[i].background}" 
                        data-border="${tags[i].border}" 
                        data-font="${tags[i].font}"> 
                        <span>${tags[i].literal}</span>
                    </div>`;
    }
    
    $(tileHeaderLeft).html(response);

    // Applies the styles for the custom Tags
    customTags.setProductTags();
}

/**
 * Dynamically update description when variation selectors changes
 * @param {Object[]} product - Array of product values
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateDescription(product, $productContainer) {
    var descriptions = $productContainer.find('[data-js="descriptions"]');

    $(descriptions).empty();

    var response = '';
    if (product.shortDescription) {
        response += '<div class="d-lg-block collapse" id="collapsible-description">';
        response += `<p class="short-description f-500">${product.shortDescription}</p>`;
        if (product.longDescription) {
            response += `<p class="long-description f-500 pt-4">${product.longDescription}</p>`;
        }
        response += '</div>';
    }
    $(descriptions).append(response);
}

/**
 * Dynamically update title when variation selectors changes
 * @param {Object[]} product - Array of product values
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateTitle(product, $productContainer) {
    var title = $productContainer.find('h1.title-detail');
    if (product.productName) {
        $(title).text(product.productName);
    }
}

/**
 * Updates the checkbox for an additional product in a set
 *
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {boolean} readyToAdd - Boolean indicating if the checkbox is enabled or not
 */
function updateAddProductCheckbox($productContainer, readyToAdd) {
    var $elemCheckbox = $('[data-js="checkbox-addAdditionalProduct"]', $productContainer);
    if ($elemCheckbox.length) {
        var $textDisabled = $elemCheckbox.closest('.row').siblings('[data-js="addon-payment-not-available"]');
        if (readyToAdd === 'true') {
            $elemCheckbox.removeAttr('disabled');
            $textDisabled.removeClass('d-none').addClass('d-none');
        } else {
            $elemCheckbox.prop('checked', false).attr('disabled', '');
            $textDisabled.removeClass('d-none');
        }
    }
}

/**
 * Compares the data in the given element with a passed quote
 * @param {jQuery} $element - The jQuery element to be compared
 * @param {Object} paymentObj - Object containing the payment selected for the main product
 * @returns {boolean} The result of the comparison
 */
function isSameQuote($element, paymentObj) {
    var sameFinancingType = isSameFinancingType($element.attr('data-financingtype'), paymentObj.financingType);

    return ($element.attr('data-quota') === paymentObj.quote
            && $element.attr('data-tin') === paymentObj.TIN
            && $element.attr('data-tae') === paymentObj.TAE
            && sameFinancingType === true
    );
}

/**
 * Dynamically update insurance price options based on selected payment
 * @param {Object} paymentObj - Object containing the payment selected for the main product
 */
function updateInsurancePriceOptions(paymentObj) {
    var productInsurance = $('.product-insurance');
    var buttonAddInsurance = productInsurance.find('.secure-btn-add');
    productInsurance.find('[data-js="insurance-not-available"]').addClass('d-none');
    $(buttonAddInsurance).addClass('disabled');
    $(buttonAddInsurance).prop('disabled', true);

    if (productInsurance) {
        var $onePayment = $(productInsurance).find('[data-js="price-onepayment"]');
        var $financed = $(productInsurance).find('[data-js="price-financed"],[data-js="price-installment"],[data-js="price-mybox"]');
        var $myBox = $(productInsurance).find('[data-js="price-mybox"]');
        $onePayment.removeClass('d-none').addClass('d-none');
        $financed.removeClass('d-none').addClass('d-none');
        $myBox.removeClass('d-none').addClass('d-none');
        var $firstCheckbox = null;

        if (paymentObj.isFinanced === false) {
            $onePayment.each(function () {
                if (parseFloat($(this).find('.value').attr('data-value')) > 0) {
                    $(this).removeClass('d-none');
                    var $theCheckbox = $(this).closest('.insurance-item').find('[data-js="checkbox-addAdditionalProduct"]');
                    $theCheckbox.prop('disabled', false);
                    if ($firstCheckbox === null) {
                        $firstCheckbox = $theCheckbox;
                    }
                } else {
                    $(this).closest('.insurance-item').find('[data-js="checkbox-addAdditionalProduct"]')
                        .prop('disabled', true)
                        .addClass('disabled');
                    $(this).siblings().find('[data-js="insurance-not-available"]').removeClass('d-none');
                }
            });
        } else {
            var $checkBoxes = productInsurance.find('[data-js="checkbox-addAdditionalProduct"]');
            $checkBoxes.each(function () {
                var $financedPrices = $(this).closest('.insurance-item')
                    .find('[data-js="price-financed"],[data-js="price-installment"],[data-js="price-mybox"]');
                var priceFound = false;
                $financedPrices.each(function () {
                    if (isSameQuote($(this), paymentObj) === true) {
                        $(this).removeClass('d-none');
                        priceFound = true;
                    }
                });
                $(this).prop('disabled', !priceFound);

                if ($(this).prop('disabled')) {
                    $(this).closest('.insurance-item').find('[data-js="insurance-not-available"]').removeClass('d-none');
                }

                if (priceFound === true && $firstCheckbox === null) {
                    $firstCheckbox = $(this);
                }
            });
        }

        if ($firstCheckbox !== null) {
            $firstCheckbox.prop('checked', true);
            $(buttonAddInsurance).removeClass('disabled');
            $(buttonAddInsurance).prop('disabled', false);
            $(productInsurance).attr('data-pid', $firstCheckbox.attr('data-pid'));
        }
    }
}

/**
 * Dynamically update complements of a set based on selected payment
 * @param {Object} paymentObj - Object containing the payment selected for the main product
 */
function updateComplements(paymentObj) {
    $('.set-items .set-item:not(:first)').each(function () {
        var $complement = $(this);
        var $onePayment = $(this).find('[data-js="price-onepayment"]');
        var $financed = $(this).find('[data-js="price-financed"], [data-js="price-installment"], [data-js="price-mybox"]');

        var readyToAdd = ($('div.availability', $complement).attr('data-ready-to-order')
            && $('div.availability', $complement).attr('data-available'));

        updateAddProductCheckbox($complement, false);

        $complement.removeClass('disabled').addClass('disabled');
        $complement.find('.not-available-text').show();
        $onePayment.removeClass('d-none').addClass('d-none');
        $financed.removeClass('d-none').addClass('d-none');

        if (paymentObj.isFinanced === false) {
            $complement.removeClass('disabled');
            $onePayment.removeClass('d-none');
            $complement.find('.not-available-text').hide();
            updateAddProductCheckbox($complement, readyToAdd);
        } else {
            $financed.each(function () {
                if (isSameQuote($(this), paymentObj) === true) {
                    $complement.removeClass('disabled');
                    $complement.find('.not-available-text').hide();
                    $(this).removeClass('d-none');
                    updateAddProductCheckbox($complement, readyToAdd);
                }
            });
        }
    });

    // reset striked list price
    var origPrice = parseFloat($('[data-js="list-total-cart-price"]').attr('data-origprice'));
    $('[data-js="list-total-cart-price"]').html(formatMoney(origPrice));
    $('[data-js="list-total-cart-price"]').attr('data-totallistprice', origPrice);

    updateInsurancePriceOptions(paymentObj);
}

/**
 * DEPRECATED. Now is used the updateQuote method
 * Dynamically recalculate one concrete quota for additional products in a set
 * @param {Object} $quote - The jQuery object to be Updated
 * @param {number} totalPrice - The new total price
 * @param {number} totalListPrice - The new total list price
*/
/*
function recalculateQuote($quote, totalPrice, totalListPrice) {
    var numQuotas = parseFloat($quote.attr('data-quota'));
    var totalFinanced = getTotalFinanced(totalPrice, numQuotas, parseFloat($quote.attr('data-tae')));
    var totalListFinanced = getTotalFinanced(totalListPrice, numQuotas, parseFloat($quote.attr('data-tae')));
    var regularQuota = roundQuota(totalFinanced / numQuotas);
    var regularListQuota = roundQuota(totalListFinanced / numQuotas);
    var lastQuota = regularQuota + (totalFinanced - (regularQuota * numQuotas));
    var lastListQuota = regularListQuota + (totalListFinanced - (regularListQuota * numQuotas));
    $quote.attr('data-referencePrice', totalPrice);
    $quote.attr('data-totalprice', totalFinanced);
    $quote.attr('data-totallistprice', totalListFinanced);
    $quote.attr('data-lastquota', lastQuota);
    $quote.attr('data-lastlistquota', lastListQuota);
    $quote.attr('content', regularQuota);
    $quote.attr('data-totallistquota', regularListQuota);
    $quote.html(formatMoney(regularQuota) + $quote.siblings('.text-financed-month').html());
}
*/

/**
 * Updates one concrete quota
 * @param {Object} $quote - The jQuery object to be Updated
 * @param {number} totalPrice - The new total price
 * @param {number} totalListPrice - The new total list price
*/
function updateQuote($quote, totalPrice, totalListPrice) {
    var numQuotas = parseFloat($quote.attr('data-quota'));
    var totalFinanced = getTotalFinanced(totalPrice, numQuotas, parseFloat($quote.attr('data-tin')));
    var totalListFinanced = getTotalFinanced(totalListPrice, numQuotas, parseFloat($quote.attr('data-tin')));
    $quote.attr('data-referencePrice', totalPrice);
    $quote.attr('data-referencelistprice', totalListPrice);
    $quote.attr('data-totalprice', totalFinanced);
    if (totalListPrice !== 'null') {
        $quote.attr('data-totallistprice', totalListFinanced);
    }
}

/**
 * Dynamically update legal information for additional products in a set
 * @param {number} totalPrice - The new total price
 * @param {number} totalListPrice - The new total list price
 * @param {boolean} addedProduct - Indicates that the call comes from adding/removing a complement
 * @param {boolean} isFinanced - Indicates that the call has to update financing payments
*/
function updateLegalInformationForSets(totalPrice, totalListPrice, addedProduct, isFinanced) {
    var $legalInformation = $('[data-js="legalInformation"]');

    $legalInformation.find('span[id="var0"]').html($('[data-js="sales-total-cart-price"]').html());

    if (isFinanced) {
        $('[data-js="installmentQuotasContainer"] [data-js="total-price-quota"]').each(function () {
            // recalculateQuote($(this), totalPrice, totalListPrice);
            updateQuote($(this), totalPrice, totalListPrice);
        });
        $('[data-js="quotasContainer"] [data-js="total-price-quota"]').each(function () {
            // recalculateQuote($(this), totalPrice, totalListPrice);
            updateQuote($(this), totalPrice, totalListPrice);
        });
        $('[data-js="suscriptionQuotasContainer"] [data-js="total-price-quota"]').each(function () {
            // recalculateQuote($(this), totalPrice, totalListPrice);
            updateQuote($(this), totalPrice, totalListPrice);
        });
        $('[data-js="cpcFinancingQuotasContainer"] [data-js="total-price-quota"]').each(function () {
            // recalculateQuote($(this), totalPrice, totalListPrice);
            updateQuote($(this), totalPrice, totalListPrice);
        });
        $('[data-js="quixFinancingQuotasContainer"] [data-js="total-price-quota"]').each(function () {
            // recalculateQuote($(this), totalPrice, totalListPrice);
            updateQuote($(this), totalPrice, totalListPrice);
        });
    }

    // updateMainPrices();
    updateFinancingInformation();
}

/**
 * Selects the payment selected when loading the page
 * @param {jQuery} $cartButton - The jQuery element for taking configuration
 */
function setInitialPayment($cartButton) {
    // When the page is loaded, we select the first financed payment if available.
    // Otherwise, we select the one payment
    var allowOnePayment = $cartButton.attr('data-allowonepayment');
    var allowFinancedPayment = $cartButton.attr('data-allowfinancedpayment');
    var allowMyBox = $cartButton.attr('data-allowmyboxpayment');
    var allowCpcPayment = $cartButton.attr('data-allowcpcpayment');
    var allowQuixPayment = $cartButton.attr('data-allowquixpayment');

    if ($('[id="quotasMybox"]')
        && $('[data-js="suscriptionQuotasContainer"] [data-js="btn-payment"]').length > 0
        && allowMyBox === 'true') {
        $('[data-js="suscriptionQuotasContainer"] [data-js="btn-payment"]').first().trigger('click');
    } else if ($('[id="installmentQuotas"]')
        && $('[data-js="installmentQuotasContainer"] [data-js="btn-payment"]').length > 0
        && allowOnePayment === 'true') {
        $('[data-js="installmentQuotasContainer"] [data-js="btn-payment"]').first().trigger('click');
    } else if ($('[id="quotasLoan"]')
        && $('[data-js="quotasContainer"] [data-js="btn-payment"]').length > 0
        && allowFinancedPayment === 'true') {
        $('[data-js="quotasContainer"] [data-js="btn-payment"]').first().trigger('click');
    } else if ($('[id="quotasCpc"]')
        && $('[data-js="cpcFinancingQuotasContainer"] [data-js="btn-payment"]').length > 0
        && allowCpcPayment === 'true') {
        $('[data-js="cpcFinancingQuotasContainer"] [data-js="btn-payment"]').first().trigger('click');
    } else if ($('[id="quotasQuix"]')
        && $('[data-js="quixFinancingQuotasContainer"] [data-js="btn-payment"]').length > 0
        && allowQuixPayment === 'true') {
        $('[data-js="quixFinancingQuotasContainer"] [data-js="btn-payment"]').first().trigger('click');
    } else {
        $('[data-js="one-payment"] [data-js="btn-payment"]').trigger('click');
    }
}

/**
 * Saves the selected payment
 * @param {jQuery} $payment - the jQuery element that contains the data
 */
function saveSelectedPayment($payment) {
    if ($payment.attr('data-is-quote') === 'true') {
        var $quote = $payment.find('[data-js="total-price-quota"]');
        selectedPayment = {
            isQuote: true,
            quotes: $quote.attr('data-quota'),
            tin: $quote.attr('data-tin'),
            tae: $quote.attr('data-tae'),
            financingtype: $quote.attr('data-financingtype')
        };
    } else {
        selectedPayment = {
            isQuote: false
        };
    }
}

/**
 * Returns the selected payment
 * @returns {Object} - The current selected payment
 */
function getSelectedPayment() {
    return selectedPayment;
}

/**
 * Dynamically change payment option
 */
function handlePaymentType() {
    $(document).on('click', '[data-js="btn-payment"]', function () {
        $('[data-js="btn-payment"]').removeClass('selected');
        $('[data-js="btn-payment"] .selected').not($(this).addClass('selected'));
        saveSelectedPayment($(this));

        var paymentObj = {
            isFinanced: false,
            quote: null,
            TIN: null,
            TAE: null,
            financingType: null
        };
        var enabledFinanced = $('[data-js="btn-add-to-cart"]').attr('data-enablefinancedpayments');
        var allowFinancedPayment = $('[data-js="btn-add-to-cart"]').attr('data-allowfinancedpayment');
        var allowMyBox = $('[data-js="btn-add-to-cart"]').attr('data-allowmyboxpayment');

        var $availability = $('.global-availability');
        var globalAvailability = $availability.length
            ? $availability.attr('data-available') === 'true' && $availability.attr('data-ready-to-order') === 'true'
            : $('.product-availability').toArray().every(function (item) {
                return $(item).attr('data-available') === 'true' && $(item).attr('data-ready-to-order') === 'true';
            });

        var legalText = $('.legal-text-pdp');
        var legalTextButton = $('.legal-text-pdp-button');

        $(legalText).addClass('d-none');
        $(legalText).removeClass('show');
        $(legalTextButton).addClass('d-none');
        $(legalTextButton).addClass('collapsed');
        $(legalTextButton).attr('aria-expanded', 'false');

        if ($(this).find('[data-quota]').length > 0) {
            paymentObj.isFinanced = true;
            var $info = $(this).find('[data-js="total-price-quota"]');
            paymentObj.quote = $info.attr('data-quota');
            paymentObj.TIN = $info.attr('data-tin');
            paymentObj.TAE = $info.attr('data-tae');
            paymentObj.financingType = $info.attr('data-financingtype');

            if (paymentObj.financingType === financingTypes.MYBOX) {
                // show subscription payment
                $('[data-js="subscription-payment-product-price"]').removeClass('d-none');
                $('[data-js="financed-payment-product-price"]').addClass('d-none');
                $('[data-js="cash-payment-product-price"]').addClass('d-none');
                $('[data-js="subscription-payment-conditions"]').removeClass('d-none');
                $('[data-js="financed-payment-conditions"]').addClass('d-none');
                $('[data-js="cash-payment-conditions"]').addClass('d-none');
            } else {
                // show financing payment
                $('[data-js="financed-payment-conditions"]').removeClass('d-none');
                $('[data-js="cash-payment-conditions"]').addClass('d-none');
                $('[data-js="subscription-payment-conditions"]').addClass('d-none');
                $('[data-js="financed-payment-product-price"]').removeClass('d-none');
                $('[data-js="cash-payment-product-price"]').addClass('d-none');
                $('[data-js="subscription-payment-product-price"]').addClass('d-none');
                $('[href="#' + $(this).closest('.tab-pane').attr('id') + '"]').tab('show'); // if is mobile
            }

            // Desactivate button add-to-cart on allowsOnePayment if enableFinancedPayments
            if (enabledFinanced !== 'true' || allowFinancedPayment !== 'true' || !globalAvailability) {
                $('[data-js="btn-add-to-cart"]').attr('disabled', 'disabled');
            }

            // Activate button add-to-cart for subscription payment
            if (allowMyBox === 'true') {
                $('[data-js="btn-add-to-cart"]').removeAttr('disabled');
            }


            // Update legal texts
            var selected = false;

            for (var i = 0; i < legalText.length; i += 1) {
                $(legalText[i]).attr('id', 'notCollapseLegalTexts');
                if ($(legalText[i]).attr('data-financing-type') === paymentObj.financingType) {
                    if ($(legalText[i]).attr('data-quota') === paymentObj.quote.toString() && !selected) {
                        $(legalText[i]).removeClass('d-none');
                        $(legalText[i]).attr('id', 'collapseLegalTexts');
                        $(legalTextButton[i]).removeClass('d-none');
                        selected = true;
                    }
                }
            }
        } else {
            // show one payment
            $('[data-js="financed-payment-conditions"]').addClass('d-none');
            $('[data-js="cash-payment-conditions"]').removeClass('d-none');
            $('[data-js="subscription-payment-conditions"]').addClass('d-none');
            $('[data-js="subscription-payment-product-price"]').addClass('d-none');
            $('[data-js="cash-payment-product-price"]').find('.financed-total').addClass('d-none');
            $('[data-js="cash-payment-product-price"]').find('.legal-text').addClass('d-none');
            $('[data-js="financed-payment-product-price"]').addClass('d-none');
            $('[data-js="cash-payment-product-price"]').removeClass('d-none');
            var priceData = $('[data-js="total-price"]').html();
            $('[data-js="cash-payment-product-price"]').find('[data-js="sales-product-price"]').html(priceData);

            // Update legal texts
            $(legalText[legalText.length - 1]).removeClass('d-none');
            $(legalText[legalText.length - 1]).attr('id', 'collapseLegalTexts');
            $(legalTextButton[legalText.length - 1]).removeClass('d-none');
        }

        // Activate button add-to-cart on allowsOnePayment if enableFinancedPayments
        /* if (enabledFinanced !== 'true' || allowFinancedPayment !== 'true') {
                $('[data-js="btn-add-to-cart"]').removeAttr('disabled');
            } */
        if (globalAvailability) {
            $('[data-js="btn-add-to-cart"]').removeAttr('disabled');
        }

        if ($('#out-of-stock').val() == 'true') {
            $('[data-js="btn-add-to-cart"]').attr('disabled', 'disabled');
        }

        const $pdpContainer = $('.container[data-is-set-and-primary-prod-available]');
        var isProductSet = $pdpContainer.attr('data-is-product-set') === 'true';
        if ($pdpContainer.length && isProductSet) {
            // eslint-disable-next-line max-len
            const isSetAndIsPrimaryProductAvailable = $pdpContainer.length && $pdpContainer.attr('data-is-set-and-primary-prod-available') === 'true';
            if (isSetAndIsPrimaryProductAvailable) {
                $('[data-js="btn-add-to-cart"]').removeAttr('disabled');
            } else {
                $('[data-js="btn-add-to-cart"]').attr('disabled', 'disabled');
            }
        }

        // Trigger event for changing the button text if this is a travel product
        $('body').trigger('detail:triggerUpdatePaymentButton', paymentObj);

        // For product sets, we reset the price and check if the complements can be added
        if ($('.set-items .set-item').length > 1) {
            var $mainPrice = $('.set-items .set-item').first().find('[data-js="main-price"]').first();
            var origPrice = $mainPrice.attr('data-origprice');
            var listPrice = $mainPrice.attr('data-origlistprice');
            $mainPrice.val(origPrice);
            $mainPrice.attr('data-totallistprice', listPrice);
            $('[data-js="sales-total-cart-price"]').attr('content', origPrice).html(formatMoney(origPrice));
            $('[data-js="total-price"]').attr('content', origPrice).html(formatMoney(origPrice));

            // Restore all financed buttons
            $('[data-js="btn-payment"][data-is-quote="true"]').each(function () {
                var $quote = $(this).find('[data-js="total-price-quota"]');
                $quote.attr('data-lastquota', $quote.attr('data-origlastsalesprice'));
                $quote.attr('data-lastlistquota', $quote.attr('data-origlastlistprice'));
                $quote.attr('content', $quote.attr('data-origsalesprice'));
                $quote.attr('data-totallistquota', $quote.attr('data-quotalistprice'));
                $quote.html(formatMoney(parseFloat($quote.attr('data-origsalesprice'))) + $quote.siblings('.text-financed-month').html());
            });

            updateComplements(paymentObj);
            updateLegalInformationForSets(origPrice, listPrice, false, true);
        }

        updateFinancingInformation();
    });

    // When the page is loaded, we select the first financed payment if available.
    // Otherwise, we select the one payment
    if ($('[data-js="btn-via-add-to-cart"]').length > 0) {
        setInitialPayment($('[data-js="btn-via-add-to-cart"]'));
    } else {
        setInitialPayment($('[data-js="btn-add-to-cart"]'));
    }
}

/**
 * Dynamically update description when variation selectors changes
 */
function handleGotoLegalInfo() {
    $(document).on('click', '[data-js="btn-info"]', function () {
        $('[data-js="legalInformation"]').find('.read-more').filter(':visible').trigger('click');
        $('html, body').animate({
            scrollTop: $('#legal-information').offset().top - $('header').outerHeight(true)
        }, 200);
    });

    $(document).on('click', 'sup', function () {
        if ($(this).attr('data-index-to')) {
            var indexTo = $(this).attr('data-index-to');
            var $elementToReach = $('sup').filter(':visible').filter('[data-index-is="' + indexTo + '"]');

            if ($elementToReach.length > 0) {
                if ($('#collapsible-legal-information').find('.read-more').filter(':visible').length > 0) {
                    $('#collapsible-legal-information').find('.read-more').trigger('click');
                }

                $('html, body').animate({
                    scrollTop: $elementToReach.offset().top - $('header').outerHeight(true)
                }, 200);
            }
        }
    });
}

/**
 * Dynamically update attributes when variation selectors changes
 * @param {Object[]} variationAttributes - Array of Attributes values
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAttributes(variationAttributes, $productContainer) {
    var listAttributes = $productContainer.find('[data-js="list-attributes"]');

    $(listAttributes).empty();

    var response = '';
    variationAttributes.forEach(element => {
        if (element.value.length > 0) {
            response += `<li class="mb-4"><h4 class="attribute-name title-section c-gray-400 pb-2">${element.label}</h4>`;
            if (element.value.length === 1) {
                response += `<span class="attribute-value">${element.value[0]}</span>`;
            } else {
                response += '<ul class= "pl-3 list-type-disc">';
                element.value.forEach(elementValue => {
                    response += `<li class="attribute-values icon-group">${elementValue}</li>`;
                });
                response += '</ul>';
            }
            response += '</li>';
        }
    });
    $(listAttributes).append(response);
}

/**
 * Dynamically update legal information
 * @param {Object[]} product - Array of product values
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateLegalInformation(product) {
    var $legalInformation = $('[data-js="legalInformation"]');
    var $productContainer = $legalInformation.closest('.product-detail');

    if ($productContainer.hasClass('product-bundle-detail')
        || $productContainer.hasClass('product-set-detail')
        || $productContainer.hasClass('bundle-item')
        || $productContainer.hasClass('set-item')) {
        $legalInformation.find('span[id="var0"]').html($('[data-js="sales-total-cart-price"]').html());
        $legalInformation.find('span[id="var4"]').html($('[data-js="sales-total-cart-price"]').html());
    } else if (typeof product !== 'undefined' && product.price && product.price.sales) {
        $legalInformation.find('span[id="var0"]').html(product.price.sales.formatted);
        $legalInformation.find('span[id="var4"]').html(product.price.sales.formatted);
    } else {
        // First load of standard product
        $legalInformation.find('span[id="var0"]').html($('[data-js="sales-product-price"]').html());
        $legalInformation.find('span[id="var4"]').html($('[data-js="sales-product-price"]').html());
    }

    updateFinancingInformation();
}

/**
 * Computes option prices for an element inside a bundle
 *
 * @param {jQuery} $bundleItemsContainer - DOM element for bundle items.
 * @param {jQuery} $priceSelector - DOM element for price showed with cart button
 * @param {jQuery} $priceFakeButton - DOM element for price showed at the end
 * @param {jQuery} $quotapriceFakeButton - DOM element for the quota price showed at the end
 * @param {HMTL} responseHTML - HTML response
 */
function processOptionsInBundleProduct($bundleItemsContainer, $priceSelector, $priceFakeButton, $quotapriceFakeButton, responseHTML) {
    var optionAddedPrice = 0.0;
    if (responseHTML !== null) {
        optionAddedPrice = parseFloat($(responseHTML).find('[data-js="sales-total-cart-price"]').attr('data-origprice'));
        $priceSelector.find('[data-js="sales-total-cart-price"]').attr('data-origprice', optionAddedPrice);
    } else {
        optionAddedPrice = parseFloat($priceSelector.find('[data-js="sales-total-cart-price"]').attr('data-origprice'));
    }
    $bundleItemsContainer.find('.product-option').each(function () {
        var $elemOption = $(this).find('.options-select');
        var optionIsSelect = $elemOption.find('option').length > 0;
        if (!optionIsSelect && $elemOption.prop('checked')) {
            optionAddedPrice += parseFloat($(this).find('.price-num').attr('content'));
        } else if (optionIsSelect && $elemOption.find('option:selected')) {
            optionAddedPrice += parseFloat($elemOption.find('option:selected').data('value'));
        }
    });
    $priceSelector.find('[data-js="sales-total-cart-price"]').attr('content', optionAddedPrice).html(formatMoney(optionAddedPrice));
    $priceFakeButton.attr('content', optionAddedPrice).html(formatMoney(optionAddedPrice));
    $quotapriceFakeButton.each(function (i, v) {
        var quota = 1;
        var quotaValue = 0;
        var $element = $(v);

        quota = $element.attr('data-quota');
        quotaValue = (optionAddedPrice / quota).toFixed(2);
        $element.attr('content', quotaValue).html(formatMoney(quotaValue));
    });
}

/**
 * Disables the 'Add to cart' button if the default option is selected in any of the select elements.
 */
function disableAddToCartIfDefaultSelected() {
    var variants = $('.custom-select');
  
    variants.each(function() {
      var defaultOption = $(this).find('option:first');
    
      if (defaultOption.prop('selected')) {
        $('button.add-to-cart').attr('disabled', true);
      }
    });
  }

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {string} response.product.productAvailable - Product available
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.variantAvailable - Product variant available
 * @param {Object[]} response.product.setAvailable - Product set available
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isPartOfBundle = $productContainer.parents('.bundle-items').length > 0;
    var isPartOfSet = $productContainer.parents('.set-items').length > 0;
    var isVariant;

    // always update product id in productcontainer
    $productContainer.attr('data-pid', response.product.id);


    if (response.product.variationAttributes) {
        updateAttrs(response.product, $productContainer, response.resources);
        isVariant = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVariant) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    if (response.product.variantAvailable) {
        $productContainer.find('.not-available-text').hide();
        $productContainer.find('.pdp-tags').empty().html(response.variationActionTagHtml);
    } else {
        $productContainer.find('.not-available-text').show();
    }

    // Update primary images
    var primaryImageUrls = response.product.images.large;
    var productVideo = response.product.productVideo
    createCarousel(primaryImageUrls, isPartOfSet, $productContainer, productVideo);

    // Update product tags
    updateTags(response.tags, $productContainer);

    // Update description product
    updateDescription(response.product, $productContainer);

    // Update title product
    updateTitle(response.product, $productContainer);

    // Update Attributes list
    updateAttributes(response.product.attributes, $productContainer);

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);
    updateAvailability(response, $productContainer);

    wishlistHelper.updateWishlistButton(response.wishlistActionHtml, $productContainer);

    // Update pricing
    var $priceSelector = $('.prices .price', $productContainer).length
        ? $('.prices .price', $productContainer)
        : $('.prices .price');
    var $priceFakeButton = $('[data-js="total-price"]');
    // var $labelTotalPrice = $('[data-js="total-pricelabel"]');
    var $quotapriceFakeButton = $('[data-js="total-price-quota"]');
    if (!isChoiceOfBonusProducts) {
        var addedPrice = 0.0;
        if (!isPartOfBundle && !isPartOfSet) {
            if ($productContainer.find('.bundle-items').length > 0) {
                processOptionsInBundleProduct(
                    $productContainer.find('.bundle-items'),
                    $priceSelector,
                    $priceFakeButton,
                    $quotapriceFakeButton,
                    response.product.price.html
                );
            } else {
                // $priceSelector.replaceWith(response.product.price.html);
                var decimalContent = 0.0;
                var htmlContainer = '';
                if (response.product.price.type === 'range') {
                    // $labelTotalPrice.html($labelTotalPrice.data('rangepricelabel'));
                    decimalContent = response.product.price.max.sales.decimalPrice;
                    htmlContainer = '' + response.product.price.min.sales.formatted + ' - ' + response.product.price.max.sales.formatted;
                } else {
                    // $labelTotalPrice.html($labelTotalPrice.data('labelonepayment'));
                    decimalContent = response.product.price.sales.decimalPrice;
                    htmlContainer = (response.product.price.sales.formatted === null)
                        ? '' + response.product.price.sales.decimalPrice
                        : '' + response.product.price.sales.formatted;
                }
                $priceFakeButton.attr('content', decimalContent).html(htmlContainer);
                $('[data-js="cash-payment-product-price"]').find('[data-js="sales-product-price"]').html(htmlContainer);
                $quotapriceFakeButton.each(function (i, v) {
                    var quota = 1;
                    var quotaValue = 0;
                    var $element = $(v);

                    quota = $element.attr('data-quota');
                    quotaValue = (decimalContent / quota).toFixed(2);
                    $element.attr('content', quotaValue).html(formatMoney(quotaValue));
                });
            }
        } else if (isPartOfBundle && !isPartOfSet) {
            processOptionsInBundleProduct($productContainer.parents('.bundle-items'), $priceSelector, $priceFakeButton, $quotapriceFakeButton, null); // eslint-disable-line
        } else {
            var totalValue = 0.0;
            var totalListValue = 0.0;
            var prevValue = 0.0;
            var prevListValue = 0.0;

            // Update price for this item
            if ($('.set-items .set-item').first().data('pid') === $productContainer.data('pid')) {
                $priceSelector.replaceWith(response.product.price.html);
                $priceSelector = $('.prices .price', $productContainer).length
                    ? $('.prices .price', $productContainer)
                    : $('.prices .price');

                $priceSelector.find('[data-js="list-product-price"]').attr('data-js', 'list-total-cart-price');
                $priceSelector.find('[data-js="sales-product-price"]').attr('data-js', 'sales-total-cart-price');

                $productContainer.find('[data-js="main-price"]')
                    .attr('value', response.product.price.sales.decimalPrice)
                    .attr('data-origprice', response.product.price.sales.decimalPrice);

                if (response.product.price.list !== 'undefined' && response.product.price.list !== null){
                    $productContainer.find('[data-js="main-price"]')
                        .attr('data-origlistprice', response.product.price.list.decimalPrice)
                        .attr('data-totallistprice', response.product.price.list.decimalPrice);
                } else {
                    $productContainer.find('[data-js="main-price"]')
                        .attr('data-origlistprice', response.product.price.sales.decimalPrice)
                        .attr('data-totallistprice', response.product.price.sales.decimalPrice);
                }
            } else {
                var $itemPrice = $productContainer.find('.price [data-js="price-onepayment"] .value');
                var $pricesContainer = $productContainer.find('div.price');
                $pricesContainer.find('[data-js="price-financed"]').remove();
                $pricesContainer.find('[data-js="price-installment"]').remove();
                $pricesContainer.find('[data-js="price-mybox"]').remove();
                if (response.product.price.type === 'range') {
                    $itemPrice.attr('data-value', response.product.price.max.sales.decimalPrice)
                        .attr('data-origprice', response.product.price.max.sales.decimalPrice)
                        .html('' + response.product.price.min.sales.formatted + ' - ' + response.product.price.max.sales.formatted);
                } else {
                    // check prices - if null assign the list or sales value
                    response.product.price.list = response.product.price.list !== null
                        ? response.product.price.list : response.product.price.sales;
                    response.product.price.sales = response.product.price.sales !== null
                        ? response.product.price.sales : response.product.price.list;

                    $itemPrice.attr('data-listprice', response.product.price.list.decimalPrice);
                    $productContainer.find('[data-js="checkbox-addAdditionalProduct"]')
                        .attr('data-listprice', response.product.price.list.decimalPrice);

                    $itemPrice.attr('data-value', response.product.price.sales.decimalPrice)
                        .attr('data-origprice', response.product.price.sales.decimalPrice);
                    $productContainer.find('[data-js="checkbox-addAdditionalProduct"]')
                        .attr('data-price', response.product.price.sales.decimalPrice);

                    if (response.product.price.sales.formatted === null) {
                        $itemPrice.html(response.product.price.sales.decimalPrice);
                    } else {
                        $itemPrice.html(response.product.price.sales.formatted);
                    }

                    var financeLabel;
                    if (response.product.allowsFinancedPayment && response.product.financingQuotas.isFinancedProduct) {
                        var financingHtml = '';
                        financeLabel = $pricesContainer.data('financelabel');
                        response.product.financingQuotas.financingArray.forEach(function (finData) {
                            var financingDiv = '<div class="col-12 d-none"'
                                                + ' data-js="price-financed"'
                                                + ' data-quota="' + finData.quotas + '"'
                                                + ' data-tin="' + finData.TIN.formatted + '"'
                                                + ' data-tae="' + finData.TAE.formatted + '"'
                                                + ' data-financingtype="' + finData.financingType + '">';
                            var financingSpan = '<h5><span class="value" '
                                                + ' data-listquota="' + finData.quotaListPrice.value + '"'
                                                + ' data-value="' + finData.quotaSalesPrice.value + '"'
                                                + ' data-origprice="' + finData.quotaSalesPrice.value + '"'
                                                + ' data-lastquota="' + finData.lastQuoteSalesPrice.value + '"'
                                                + ' data-lastlistquota="' + finData.lastQuoteListPrice.value + '">'
                                                + '+ ' + finData.quotaSalesPrice.formatted + financeLabel
                                                + '</span></h5></div>';
                            financingHtml = financingHtml + financingDiv + financingSpan;
                        });
                        $pricesContainer.append(financingHtml);
                        $productContainer.find('[data-js="checkbox-addAdditionalProduct"]').attr('data-isfinanced', true);
                    } else {
                        $pricesContainer.find('[data-js="price-financed"]').remove();
                        $productContainer.find('[data-js="checkbox-addAdditionalProduct"]').attr('data-isfinanced', false);
                    }

                    if (response.product.installmentQuotas.isFinancedProduct) {
                        var installmentHtml = '';
                        financeLabel = $pricesContainer.data('financelabel');
                        var installmentLabel = $pricesContainer.data('installmentlabel');
                        response.product.installmentQuotas.financingArray.forEach(function (finData) {
                            var installmentDiv = '<div class="col-12 d-none"'
                                                + ' data-js="price-installment"'
                                                + ' data-quota="' + finData.quotas + '"'
                                                + ' data-tin="' + finData.TIN.formatted + '"'
                                                + ' data-tae="' + finData.TAE.formatted + '"'
                                                + ' data-financingtype="' + finData.financingType + '">';
                            var installmentSpan = '<h5><span class="value"'
                                                + ' data-listquota="' + finData.quotaListPrice.value + '"'
                                                + ' data-value="' + finData.quotaSalesPrice.value + '"'
                                                + ' data-origprice="' + finData.quotaSalesPrice.value + '"'
                                                + ' data-lastquota="' + finData.lastQuoteSalesPrice.value + '"'
                                                + ' data-lastlistquota="' + finData.lastQuoteListPrice.value + '">'
                                                + '+ ' + finData.quotaSalesPrice.formatted + financeLabel
                                                + '</span></h5>';
                            var installmentTotalValue = '<p><span class="mt-1 value-total"'
                                                + ' data-referencelistprice="' + finData.referenceListPrice.value + '"'
                                                + ' data-referencesalesprice="' + finData.referenceSalesPrice.value + '"'
                                                + ' data-totalsalesprice="' + finData.totalSalesPrice.value + '"'
                                                + ' data-totallistprice="' + finData.totalListPrice.value + '">'
                                                + installmentLabel + ' ' + finData.totalSalesPrice.formatted
                                                + '</p></div>';
                            installmentHtml = installmentHtml + installmentDiv + installmentSpan + installmentTotalValue;
                        });
                        $pricesContainer.append(installmentHtml);
                        $productContainer.find('[data-js="checkbox-addAdditionalProduct"]').attr('data-isinstallment', true);
                    } else {
                        $pricesContainer.find('[data-js="price-installment"]').remove();
                        $productContainer.find('[data-js="checkbox-addAdditionalProduct"]').attr('data-isinstallment', false);
                    }

                    if (response.product.subscriptionQuotas.isFinancedProduct) {
                        var myboxHtml = '';
                        financeLabel = $pricesContainer.data('financelabel');
                        response.product.subscriptionQuotas.financingArray.forEach(function (finData) {
                            var myboxDiv = '<div class="col-12 d-none"'
                                                + ' data-js="price-mybox"'
                                                + ' data-quota="' + finData.quotas + '"'
                                                + ' data-tin="' + finData.TIN.formatted + '"'
                                                + ' data-tae="' + finData.TAE.formatted + '"'
                                                + ' data-financingtype="' + finData.financingType + '">';
                            var myboxSpan = '<h5><span class="value" '
                                                + ' data-listquota="' + finData.quotaListPrice.value + '"'
                                                + ' data-value="' + finData.quotaSalesPrice.value + '"'
                                                + ' data-origprice="' + finData.quotaSalesPrice.value + '"'
                                                + ' data-lastquota="' + finData.lastQuoteSalesPrice.value + '"'
                                                + ' data-lastlistquota="' + finData.lastQuoteListPrice.value + '">'
                                                + '+ ' + finData.quotaSalesPrice.formatted + financeLabel
                                                + '</span></h5></div>';
                            myboxHtml = myboxHtml + myboxDiv + myboxSpan;
                        });
                        $pricesContainer.append(myboxHtml);
                        $productContainer.find('[data-js="checkbox-addAdditionalProduct"]').attr('data-ismybox', true);
                    } else {
                        $pricesContainer.find('[data-js="price-mybox"]').remove();
                        $productContainer.find('[data-js="checkbox-addAdditionalProduct"]').attr('data-ismybox', false);
                    }
                }
            }

            // Recalculate total set price
            $('.set-items .set-item').each(function (index) {
                addedPrice = 0.0;

                var $elemOption = $(this).find('.product-option .options-select');
                if ($elemOption.length > 0) {
                    var optionIsSelect = $elemOption.find('option').length > 0;
                    if (!optionIsSelect && $elemOption.prop('checked')) {
                        addedPrice += parseFloat($(this).find('.price-num').attr('content'));
                    } else if (optionIsSelect && $elemOption.find('option:selected')) {
                        addedPrice += parseFloat($elemOption.find('option:selected').data('value'));
                    }
                }

                if (index === 0) {
                    var $elemMain = $(this).find('[data-js="main-price"]');
                    prevValue = parseFloat($elemMain.attr('data-origprice'));
                    prevListValue = parseFloat($elemMain.attr('data-origlistprice'));
                    totalValue += prevValue + addedPrice;
                    totalListValue += prevListValue + addedPrice;
                } else {
                    var $elemPrice = $(this).find('.price .value');
                    prevValue = parseFloat($elemPrice.attr('data-origprice'));
                    prevListValue = parseFloat($elemPrice.attr('data-origlistprice'));
                    var $elemCheckbox = $(this).find('[data-js="checkbox-addAdditionalProduct"]');
                    $elemCheckbox.data('price', (prevValue + addedPrice));
                    if ($elemCheckbox.prop('checked')) {
                        totalValue += prevValue + addedPrice;
                        totalListValue += prevListValue + addedPrice;
                    }
                }
            });
            $('body').trigger('product:updateSetPrices', [
                totalValue,
                totalListValue,
                response.product.financingQuotas.isFinancedProduct || response.product.installmentQuotas.isFinancedProduct,
                false,
                0
            ]);
        }
    }

    // trigger click on selected payment method to refresh the complements price labels
    $('[data-js="btn-payment"].selected').trigger('click');

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));

    // Show/hide the quotas
    if (!$productContainer.hasClass('set-item') || ($productContainer.data('pid') === $('.col-product-detail .product-detail').first().data('pid'))) {
        $('[id="itemQuotas"]').removeClass('d-none').addClass('d-none');
        $('[id="installmentQuotas"]').removeClass('d-none').addClass('d-none');
        $('[id="quotasLoan"]').removeClass('d-none').addClass('d-none');
        $('[id="quotasMybox"]').removeClass('d-none').addClass('d-none');
        $('[id="contentPrices"]').removeClass('d-none').addClass('d-none');
        // We ensure that the onepayment button is selected
        $('[data-js="one-payment"] [data-js="btn-payment"]').trigger('click');

        var hasLoanPayment = response.product.financingQuotas.isFinancedProduct && response.product.allowsFinancedPayment;
        var hasInstallmentPayment = response.product.allowsOnePayment && response.product.installmentQuotas.isFinancedProduct;
        if(hasLoanPayment || hasInstallmentPayment){
            $('[id="contentPrices2"]').removeClass('d-none');
            // We ensure that the onepayment button is selected
            $('[data-js="one-payment"] [data-js="btn-payment"]').trigger('click');
        }

        if(response.product.allowsOnePayment === true){
            $('[id="onePayment"]')
                .removeClass('d-none')
                .empty()
                .html(response.product.onePaymentHtml);
            // We ensure that the onepayment button is selected
            $('[data-js="one-payment"] [data-js="btn-payment"]').trigger('click');
        }

        if (response.product.installmentQuotas.isFinancedProduct === true) {
            $('[id="itemQuotas"]').removeClass('d-none');
            $('[id="installmentQuotas"]')
                .removeClass('d-none')
                .find('[data-js="installmentQuotasContainer"]').empty()
                .html(response.product.installmentFinancingHtml);
            $('[id="contentPrices"]').removeClass('d-none');
            // We ensure that first financed button is selected
            $('[data-js="installmentQuotasContainer"] [data-js="btn-payment"]').first().trigger('click');
        }
        if (response.product.financingQuotas.isFinancedProduct === true) {
            $('[id="itemQuotas"]').removeClass('d-none');
            $('[id="quotasLoan"]')
                .removeClass('d-none')
                .find('[data-js="quotasContainer"]').empty()
                .html(response.product.loanFinancingHtml);
            $('[id="contentPrices"]').removeClass('d-none');
            // We ensure that first financed button is selected if there are no installment payment
            if (response.product.installmentQuotas.isFinancedProduct !== true) {
                $('[data-js="quotasContainer"] [data-js="btn-payment"]').first().trigger('click');
            }
        }
        if (response.product.subscriptionQuotas.isFinancedProduct === true) {
            $('[id="itemQuotas"]').removeClass('d-none');
            $('[id="quotasMyBox"]')
                .removeClass('d-none')
                .find('[data-js="suscriptionQuotasContainer"]').empty()
                .html(response.product.myboxFinancingHtml);
            // We ensure that first financed button is selected
            $('[data-js="suscriptionQuotasContainer"] [data-js="btn-payment"]').first().trigger('click');
        }

        if (response.product.cpcQuotas && response.product.cpcQuotas.isFinancedProduct === true) {
            $('[id="itemQuotas"]').removeClass('d-none');
            $('[id="quotasCpc"]')
                .removeClass('d-none')
                .find('[data-js="cpcFinancingQuotasContainer"]').empty()
                .html(response.product.myboxFinancingHtml);
            // We ensure that first financed button is selected
            $('[data-js="cpcFinancingQuotasContainer"] [data-js="btn-payment"]').first().trigger('click');
        }

        if (response.product.quixQuotas && response.product.quixQuotas.isFinancedProduct === true) {
            $('[id="itemQuotas"]').removeClass('d-none');
            $('[id="quotasQuix"]')
                .removeClass('d-none')
                .find('[data-js="quixFinancingQuotasContainer"]').empty()
                .html(response.product.myboxFinancingHtml);
            // We ensure that first financed button is selected
            $('[data-js="quixFinancingQuotasContainer"] [data-js="btn-payment"]').first().trigger('click');
        }
        
    }

    // Disable add to cart button
    if (response.product.productType !== 'optionProduct') {
        disableAddToCartIfDefaultSelected();
    }
    // Update legal information
    updateLegalInformation(response.product);
}

/**
 * @typespec UpdatedQuantity
 * @type Object
 * @property {boolean} selected - Whether the quantity has been selected
 * @property {string} value - The number of products to purchase
 * @property {string} url - Compiled URL that specifies variation attributes, product ID, options,
 *     etc.
 */

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if (!($productContainer.parent('.bonus-product-item').length > 0)) {
        var optionsHtml = quantities.map(function (quantity) {
            var selected = quantity.selected ? ' selected ' : '';
            return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"'
                + selected + '>' + quantity.value + '</option>';
        }).join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect', {
            url: selectedValueUrl,
            container: $productContainer,
        });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                updateOptions(data.product.optionsHtml, $productContainer);
                updateBundles(data.product, $productContainer);            
                handleVariantResponse(data, $productContainer);
                updateQuantities(data.product.quantities, $productContainer);
                $('body').trigger("product:afterAttributeSelect", {
                    data: data,
                    container: $productContainer,
                });
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <span class="">' + data.labels.selectprods + '</span>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    }
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var arrOptions = [];
    if ($productContainer.hasClass('product-detail')) {
        arrOptions = $productContainer
            .find('.product-options').first()
            .find('.product-option')
            .map(function () {
                var $elOption = $(this).find('.options-select');
                var urlValue = $elOption.val();
                var selectedValueId;
                if ($elOption.prop('tagName') === 'INPUT') {
                    selectedValueId = $elOption.data('value-id');
                } else {
                    selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                        .data('value-id');
                }
                return {
                    optionId: $(this).data('option-id'),
                    selectedValueId: selectedValueId
                };
            })
            .toArray();
    }

    return arrOptions.length > 0 ? JSON.stringify(arrOptions) : null;
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 * @param {jquery} $el - DOM container for the products in the bundle
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts($el) {
    var childProducts = [];
    $el.find('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).data('pid'),
            // quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
            quantity: 1
        });
        var itemOptions = getOptions($(this));
        if (itemOptions !== null) {
            childProducts[childProducts.length - 1].options = itemOptions;
        }
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

/**
 * Checks if a complement or insurance has been checked
 *
 * @param {jQuery} $element - The element that should contain the checkbox
 * @returns {boolean} - True if complement is checked or some insurance has been checked
 */
function hasChecked($element) {
    var someChecked = false;

    if ($element.hasClass('product-insurance')) {
        $element.find('[data-js="checkbox-addAdditionalProduct').each(function () {
            someChecked = someChecked || $(this).prop('checked');
        });
    } else {
        someChecked = $element.find('[data-js="checkbox-addAdditionalProduct').prop('checked');
    }

    return someChecked;
}

/**
 * get information from the cart
 *
 */
function cartInfo() {
    $.spinner().start();

    var actionUrl = $('.basket-get-action').attr('data-url');
    $.ajax({
        url: actionUrl,
        type: 'get',
        dataType: 'json',
        success: function (data) {
            var productID = data.result.id;
            var productName = data.result.name;
            var uuid = data.result.uuid;

            var $productToRemoveSpan = $('.product-to-remove');
            var $deleteConfirmBtn = $('.cart-delete-replace-confirmation-button');

            $deleteConfirmBtn.attr('pid', productID);
            $deleteConfirmBtn.attr('action', $('.product-delete-action').attr('data-url'));
            $deleteConfirmBtn.attr('uuid', uuid);

            $productToRemoveSpan.empty().append(productName);

            $.spinner().stop();
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                $.spinner().stop();
            }
        }
    });
}

module.exports = {
    attributeSelect: attributeSelect,
    methods: {
        editBonusProducts: function (data) {
            chooseBonusProducts(data);
        }
    },

    focusChooseBonusProductModal: function () {
        $('body').on('shown.bs.modal', '#chooseBonusProductModal', function () {
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'true');
            $('#chooseBonusProductModal .close').focus();
        });
    },

    onClosingChooseBonusProductModal: function () {
        $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'false');
        });
    },

    trapChooseBonusProductModalFocus: function () {
        $('body').on('keydown', '#chooseBonusProductModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#chooseBonusProductModal',
                firstElementSelector: '.close',
                lastElementSelector: '.add-bonus-products'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },

    colorAttribute: function () {
        $(document).on('click', '[data-attr="color"] button', function (e) {
            e.preventDefault();

            var isUnselectable = $(this).find('.selector-color-container').hasClass('unselectable');

            if ($(this).attr('disabled') || isUnselectable) {
                return;
            }

            var $productContainer = $(this).closest('.product-detail');

            attributeSelect($(this).attr('data-reseturl'), $productContainer);
        });
    },

    selectAttribute: function () {
        $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.product-detail');
            attributeSelect(e.currentTarget.value, $productContainer);
        });
    },

    availability: function () {
        $(document).on('change', '.quantity-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.modal-content').find('.product-quickview');
            }

            if ($('.bundle-items', $productContainer).length === 0) {
                attributeSelect($(e.currentTarget).find('option:selected').data('url'),
                    $productContainer);
            }
        });
    },

    addToCart: function () {
        $(document).on('click', '.secure-btn-add', function () {
            var insuranceProduct;
            var insuranceProducts = $('body').find('.product-insurance');

            if (insuranceProducts.length > 0) {
                insuranceProduct = insuranceProducts[0];
                var checkBox = $(insuranceProduct).find('[data-js="checkbox-addAdditionalProduct"]');
                $(checkBox).attr('data-processed', 'true');

                $(insuranceProduct).modal('hide');
            }

            $('button.add-to-cart, button.add-to-cart-global').trigger('click');
        });

        $(document).on('click', '.secure-btn-no', function () {
            var insuranceProduct;
            var insuranceProducts = $('body').find('.product-insurance');

            if (insuranceProducts.length > 0) {
                insuranceProduct = insuranceProducts[0];
                var checkBoxes = $(insuranceProduct).find('[data-js="checkbox-addAdditionalProduct"]');
                $(checkBoxes).each(function () {
                    $(this).prop('checked', false);
                    $(this).attr('data-processed', 'true');
                });

                $(insuranceProduct).modal('hide');
            }

            $('button.add-to-cart, button.add-to-cart-global').trigger('click');
        });

        $(document).on('click', '[id^="insurance-"]', function () {
            var matchingId = $(this).attr('id');
            var $insuranceProducts = $('body').find('.product-insurance');
            var someChecked = false;
            var productId = '';
            $insuranceProducts.find('[data-js="checkbox-addAdditionalProduct"]').each(function () {
                if ($(this).attr('id') !== matchingId) {
                    $(this).prop('checked', false);
                } else {
                    someChecked = $(this).prop('checked');
                    productId = $(this).attr('data-pid');
                }
            });
            var $addButton = $insuranceProducts.find('.secure-btn-add');
            if (someChecked === true) {
                $addButton.removeClass('disabled').prop('disabled', false);
            } else {
                $addButton.removeClass('disabled').addClass('disabled').prop('disabled', true);
            }
            $insuranceProducts.attr('data-pid', productId);
        });

        $(document).on('click', '[data-js="btn-add-to-cart"]', function () {
            var minicartCountOfItems = $('.minicart-quantity').html().trim();
            $('[data-js="btn-add-to-cart"]').attr("disabled", true);

            if (minicartCountOfItems > 0) {
                $('.prices-add-to-cart-actions').css('z-index', 'auto');
                $('.cart-and-ipay').data('class-ref', $(this).attr('class'));
                $('#replaceProductModal').modal('show');
                $('.modal-backdrop').css('display', 'none');
                $('#replaceProductModal').css('background', 'rgba(0,0,0,0.5)');
                $('[data-js="btn-add-to-cart"]').attr("disabled", false);
                cartInfo();
            } else {
                var addToCartUrl;
                var pid;
                var pidsObj;
                var setPids;
                var insuranceProduct;
                var insuranceProducts = $('body').find('.product-insurance');
                // eslint-disable-next-line max-len
                var mainProductFinanceData = getMainFinanceData($(this).closest('.product-detail').find('[data-type="financingContainer"]'));

                if (insuranceProducts.length > 0) {
                    insuranceProduct = insuranceProducts[0];
                    var checkBox = $(insuranceProduct).find('[data-js="checkbox-addAdditionalProduct"]');
                    if ($(checkBox).attr('data-processed') === 'false') {
                        $(insuranceProduct).modal('show');

                        return;
                    }
                    $(checkBox).attr('data-processed', 'false');
                }

                $('body').trigger('product:beforeAddToCart', this);

                if ($('.set-items').length) {
                    setPids = [];

                    $('.set-items .set-item').each(function (index) {
                        var itemOptions;
                        if (index < 1 || hasChecked($(this))) {
                            var itemFinanceData = null;
                            if (index === 0) {
                                itemFinanceData = mainProductFinanceData;
                            } else {
                                itemFinanceData = getFinanceData(this);
                            }
                            // if financing data is not equal, product is not added
                            if (equivalentQuotes(mainProductFinanceData.selectedQuote, itemFinanceData.selectedQuote) === true) {
                                if ($(this).hasClass('bundle-items')) {
                                    setPids.push({
                                        pid: $(this).attr('data-pid'),
                                        childProducts: JSON.parse(getChildProducts($(this))),
                                        mid: $(this).data('master-pid'),
                                        selectedQuote: itemFinanceData.selectedQuote,
                                        paymentMethod: itemFinanceData.paymentMethod,
                                        // qty: $(this).find('.quantity-select').val(),
                                        qty: 1
                                    });
                                    itemOptions = getOptions($(this));
                                    if (itemOptions !== null) {
                                        setPids[setPids.length - 1].options = itemOptions;
                                    }
                                } else {
                                    setPids.push({
                                        pid: $(this).attr('data-pid'),
                                        mid: $(this).data('master-pid'),
                                        selectedQuote: itemFinanceData.selectedQuote,
                                        paymentMethod: itemFinanceData.paymentMethod,
                                        // qty: $(this).find('.quantity-select').val(),
                                        qty: 1
                                    });
                                    itemOptions = getOptions($(this));
                                    if (itemOptions !== null) {
                                        setPids[setPids.length - 1].options = itemOptions;
                                    }
                                }
                            }
                        }
                    });
                    pidsObj = JSON.stringify(setPids);
                }

                pid = getPidValue($(this));

                var $productContainer = $(this).closest('.product-detail');
                if (!$productContainer.length) {
                    $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
                }

                addToCartUrl = getAddToCartUrl();

                var realProductsObject = JSON.parse(pidsObj || '[]');
                var mostImpId = realProductsObject.length > 0 ? realProductsObject[0].pid : null;

                var form = {
                    pid: pid,
                    pidsObj: pidsObj,
                    mostImpId: mostImpId,
                    childProducts: $('.set-items').length ? [] : getChildProducts($('.bundle-items')),
                    selectedQuote: mainProductFinanceData.selectedQuote,
                    paymentMethod: mainProductFinanceData.paymentMethod,
                    // quantity: getQuantitySelected($(this))
                    quantity: 1
                };

                if ($(this).data('mostimpid')) {
                    mostImpId = $(this).data('mostimpid');
                    var cont = $('body').find('[data-pid="' + mostImpId + '"');
                    if (cont.length > 0) {
                        mostImpId = cont.find('.product-id').html();
                    }
                }

                if (!$('.product-bundle-detail').length) {
                    if (!$productContainer.hasClass('product-set-detail')) {
                        form.options = getOptions($productContainer);
                    }
                } else {
                    var $bundleOptions = $('.product-bundle-detail');
                    if ($bundleOptions.length) {
                        form.options = getOptions($bundleOptions);
                    }
                }

                $(this).trigger('updateAddToCartFormData', form);
                if (addToCartUrl) {
                    $.ajax({
                        url: addToCartUrl,
                        method: 'POST',
                        data: form,
                        success: function (data) {
                            handlePostCartAdd(data);
                            $('[data-js="btn-add-to-cart"]').attr("disabled", false);
                            $('body').trigger('product:afterAddToCart', data);
                            miniCartReportingUrl(data.reportingURL);
							$.spinner().stop();
                            // eslint-disable-next-line no-restricted-globals
                            if (!window.isMobile()) {
                            sessionStorage.setItem('showMiniCart', 'true');
                            location.reload();
                            } else {
                                window.location.href = $('.minicart-link').attr('href');
                            }
                        },
                        error: function () {
                            $.spinner().stop();
                        }
                    });
                }
            }
        });

        // Request more Information at VIA and alarms productc - 1B phase
        $(document).on('click', 'button.more-information', function () {
            $('[data-target="#collapsible-more-information"]').trigger('click');
            scrollAnimate($('[data-js="moreInformation"]'));
        });
    },
    selectBonusProduct: function () {
        $(document).on('click', '.select-bonus-product', function () {
            var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
            var pid = $(this).data('pid');
            var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
            var submittedQty = parseInt($choiceOfBonusProduct.find('.bonus-quantity-select').val(), 10);
            var totalQty = 0;
            $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
                totalQty += $(this).data('qty');
            });
            totalQty += submittedQty;
            var optionID = $choiceOfBonusProduct.find('.product-option').data('option-id');
            var valueId = $choiceOfBonusProduct.find('.options-select option:selected').data('valueId');
            if (totalQty <= maxPids) {
                var selectedBonusProductHtml = ''
                + '<div class="selected-pid row" '
                + 'data-pid="' + pid + '"'
                + 'data-qty="' + submittedQty + '"'
                + 'data-optionID="' + (optionID || '') + '"'
                + 'data-option-selected-value="' + (valueId || '') + '"'
                + '>'
                + '<div class="col-sm-11 col-9 bonus-product-name" >'
                + $choiceOfBonusProduct.find('.product-name').html()
                + '</div>'
                + '<div class="col-1"><i class="fa fa-times" aria-hidden="true"></i></div>'
                + '</div>';
                $('#chooseBonusProductModal .selected-bonus-products').append(selectedBonusProductHtml);
                $('.pre-cart-products').html(totalQty);
                $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
            } else {
                $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
            }
        });
    },
    removeBonusProduct: function () {
        $(document).on('click', '.selected-pid', function () {
            $(this).remove();
            var $selected = $('#chooseBonusProductModal .selected-bonus-products .selected-pid');
            var count = 0;
            if ($selected.length) {
                $selected.each(function () {
                    count += parseInt($(this).data('qty'), 10);
                });
            }

            $('.pre-cart-products').html(count);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
        });
    },
    enableBonusProductSelection: function () {
        $('body').on('bonusproduct:updateSelectButton', function (e, response) {
            $('button.select-bonus-product', response.$productContainer).attr('disabled',
                (!response.product.readyToOrder || !response.product.available));
            var pid = response.product.id;
            $('button.select-bonus-product', response.$productContainer).data('pid', pid);
        });
    },
    showMoreBonusProducts: function () {
        $(document).on('click', '.show-more-bonus-products', function () {
            var url = $(this).data('url');
            $('.modal-content').spinner().start();
            $.ajax({
                url: url,
                method: 'GET',
                success: function (html) {
                    var parsedHtml = parseHtml(html);
                    $('.modal-body').append(parsedHtml.body);
                    $('.show-more-bonus-products:first').remove();
                    $('.modal-content').spinner().stop();
                },
                error: function () {
                    $('.modal-content').spinner().stop();
                }
            });
        });
    },
    addBonusProductsToCart: function () {
        $(document).on('click', '.add-bonus-products', function () {
            var $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
            var queryString = '?pids=';
            var url = $('.choose-bonus-product-dialog').data('addtocarturl');
            var pidsObject = {
                bonusProducts: []
            };

            $.each($readyToOrderBonusProducts, function () {
                var qtyOption = parseInt($(this)
                    .data('qty'), 10);

                var option = null;
                if (qtyOption > 0) {
                    if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                        option = {};
                        option.optionId = $(this).data('optionid');
                        option.productId = $(this).data('pid');
                        option.selectedValueId = $(this).data('option-selected-value');
                    }
                    pidsObject.bonusProducts.push({
                        pid: $(this).data('pid'),
                        qty: qtyOption,
                        options: [option]
                    });
                    pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
                }
            });
            queryString += JSON.stringify(pidsObject);
            queryString = queryString + '&uuid=' + $('.choose-bonus-product-dialog').data('uuid');
            queryString = queryString + '&pliuuid=' + $('.choose-bonus-product-dialog').data('pliuuid');
            $.spinner().start();
            $.ajax({
                url: url + queryString,
                method: 'POST',
                success: function (data) {
                    $.spinner().stop();
                    if (data.error) {
                        $('#chooseBonusProductModal').modal('hide');
                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append('<div class="add-to-cart-messages"></div>');
                        }
                        $('.add-to-cart-messages').append(
                            '<div class="alert alert-danger add-to-basket-alert text-center"'
                            + ' role="alert">'
                            + data.errorMessage + '</div>'
                        );
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                        }, 3000);
                    } else {
                        $('.configure-bonus-product-attributes').html(data);
                        $('.bonus-products-step2').removeClass('hidden-xl-down');
                        $('#chooseBonusProductModal').modal('hide');

                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append('<div class="add-to-cart-messages"></div>');
                        }
                        $('.minicart-quantity').html(data.totalQty);
                        $('.add-to-cart-messages').append(
                            '<div class="alert alert-success add-to-basket-alert text-center"'
                            + ' role="alert">'
                            + data.msgSuccess + '</div>'
                        );
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                            if ($('.cart-page').length) {
                                // eslint-disable-next-line no-restricted-globals
                                location.reload();
                            }
                        }, 1500);
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    getPidValue: getPidValue,
    getQuantitySelected: getQuantitySelected,
    miniCartReportingUrl: miniCartReportingUrl,
    formatMoney: formatMoney,
    updateAddProductCheckbox: updateAddProductCheckbox,
    updateLegalInformation: updateLegalInformation,
    updateLegalInformationForSets: updateLegalInformationForSets,
    updateFinancingInformation: updateFinancingInformation,
    handlePaymentType: handlePaymentType,
    handleGotoLegalInfo: handleGotoLegalInfo,
    getMainFinanceData: getMainFinanceData,
    getAddToCartUrl: getAddToCartUrl,
    paymentMethods: paymentMethods,
    isSameQuote: isSameQuote,
    setInitialPayment: setInitialPayment,
    getSelectedPayment: getSelectedPayment,
    resizeCarouselItemOfVideo: resizeCarouselItemOfVideo,
    handlePostCartAdd: handlePostCartAdd
};
