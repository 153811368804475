'use strict';

/**
 * Applies the styles for the custom Tags of a product
 */
function setProductTags() {
    var tags = $('body').find('[data-js="product-tag"]');
    var font;
    for (var i = 0; i < tags.length; i += 1) {
        tags[i].style.setProperty('--tag-background', tags[i].getAttribute('data-background'));
        font = tags[i].getAttribute('data-font').split(' ');
        tags[i].style.setProperty('--tag-font-weight', font[0]);
        tags[i].style.setProperty('--tag-font-size', font[1]);
        tags[i].style.setProperty('--tag-font-color', font[2]);
        if (tags[i].getAttribute('data-border').length !== 0) {
            tags[i].style.setProperty('--tag-border', 'solid ' + tags[i].getAttribute('data-border'));
        }
    }
}


module.exports = {
    setProductTags: setProductTags
};