'use strict';

var $showmenu = false;

/**
 * Show responsive menu level 1
 */
function showSubmenu1() {
    $('[data-js="menu-group"]').removeClass('show-subcategory');
    $('[data-js="submenu-level-2"]').removeClass('open');
    $('[data-js="submenu-link-2"]').removeClass('open');
    $('[data-js="submenu-level-3"]').removeClass('show');
    $('[data-js="icon-level-2"]').removeClass('icon-dropdown-up');
    $('.icon-minus').removeClass('icon-minus');
}

/**
 * Hide menú
 */
function hidemenu() {
    $('span.nav-link').removeClass('span-colored');
    $('span.separator').removeClass('separator-colored');
    $('div.submenu.submenu-level-2').each(function (i, submenu) {
        $(submenu).removeClass('d-block');
        if (!$(submenu).hasClass('d-none')) {
            $(submenu).addClass('d-none');
        }
    });
}

/**
 * Show responsive menu level 1 with click
 * @param {Object} obj - menu index to open
 */
function showSubmenu1v2(obj) {
    $showmenu = true;
    hidemenu();
    $(obj).children('div.submenu.submenu-level-2').addClass('d-block');
    $(obj).children('span.nav-link').addClass('span-colored');
    $(obj).children('span.separator').addClass('separator-colored');
}

/**
 * Show or hide menú
 */
function showOrHide() {
    if (!$showmenu) {
        hidemenu();
    }
    $showmenu = false;
}

/**
 * Show responsive menu level 2
 * @param {string} index - menu index to open
 */
function showSubmenu2(index) {
    $('[data-js="menu-group"]').addClass('show-subcategory');
    $('[data-js="submenu-level-2"].d-block').addClass('open');
}

/**
 * Show responsive menu level 1
 * @param {Object} obj - menu index to open
 */
function showSubmenu3(obj) {
    $(obj).toggleClass('open');
    $(obj.next('[data-js="submenu-level-3"]')).toggleClass('show');
    $(obj.find('[data-js="icon-level-2"]')).toggleClass('icon-minus');
}

/**
 * Creates a veil
 * @param {*} opacity - opacity of the veil
 */
function drawVeilHelper(opacity) {
    // Create veil (delete any existing veils)
    jQuery('#veil-liev').remove();
    var veil = jQuery("<div id='veil-liev' data-js='veil'></div>");
    // Style veil
    veil.css({
        opacity: 0
    });

    // Append veil
    jQuery('#maincontent').append(veil);
    veil = jQuery('#veil-liev');
    veil.fadeTo(250, opacity);
}

module.exports = function () {
    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    var headerBannerContent = $('.header-banner .content').children().length;

    if (headerBannerContent > 0) {
        $('.header-banner .close').on('click', function () {
            $('body').addClass('has-banner');
            $('.header-banner').addClass('hide');
            // reset styles for main, menu mobile, header compare
            $('main, .navbar-collapse, .submenu-level-2, .product-header').removeAttr('style');
            window.sessionStorage.setItem('hide_header_banner', '1');
        });

        if (!headerBannerStatus || headerBannerStatus < 0) {
            $('body').addClass('has-banner');
            $('.header-banner').removeClass('hide');
            // calculate styles for main, menu mobile, header compare
            setTimeout(function () {
                $('main').css('padding-top', $('header').innerHeight()); // main position
                $('.navbar-collapse, .submenu-level-2').css('top', $('header').innerHeight()); // menu mobile position
            }, 800);
        }
    } else {
        $('.header-banner').addClass('hide');
    }

    // $(document).click(function () {
    //     showOrHide();
    // });
    $('[data-js="navbar"]').on('click', '[data-js="show-level-1"]', function () {
        showSubmenu1();
    });
    $('[data-js="navbar-toggler"]').click(function () {
        $('body, html').toggleClass('overflow-y');
        showSubmenu1();
        if ($('body').hasClass('overflow-y')) {
            drawVeilHelper(0.60);
        } else {
            jQuery('#veil-liev').remove();
        }

        // When is the sidemenu is occuping the full width of the screen
        if (window.innerWidth <= 599 && $('#navbarCollapse').is(':visible')) {
            $('body').css('overflow-y', 'hidden');
        }
    });
    window.addEventListener('click', function (event) {
        if (event.target.id === 'text-link') {
            $('[data-js="navbar-toggler"]').trigger('click');
        }
    });
    $('[data-js="navbar"]').on('click', '[data-js="show-level-2"]', function () {
        showSubmenu2($(this).data('index'));
    });
    $('[data-js="submenu-link-2"]').click(function () {
        showSubmenu3($(this));
    });
    // $('.span-menulink').click(function () {
    //     window.location.href = $(this).data('url');
    // });
    $('.nav-item.submenu-item-1').click(function () {
        // 1199 is the max width on a tablet
        if (window.innerWidth > 1199) {
            window.location.href = $(this).data('url');
        } else {
            showSubmenu1v2($(this));
        }
    });
};

window.addEventListener('click', function (event) {
    if (event.target.id === 'veil-liev') {
        $('[data-js="navbar-toggler"]').trigger('click');
    }
});
