/* global Swiper */

'use strict';

window.Swiper = require('../thirdParty/swiper.bundle.min');

var carouselManager = {
    carousels: {},

    /**
     * Carousel init
     *
     * @returns {void}
     */
    initCarousel: function () {
        $('.swiper-container')
            .filter(function () {
                return this.id && !carouselManager.carousels[this.id];
            })
            .each(function (i, v) {
                var config = {};
                var value = $(v);
                try {
                    // data-config can contain a json with properties
                    if (value.attr('data-config') && value.attr('data-config') !== '') {
                        config = value.attr('data-config');
                    }

                    config = JSON.parse(config);
                } catch (err) {
                    // Don't handle invalid configurations
                }
                carouselManager.carousels[this.id] = new Swiper(this, config);
            });
    },

    tileTitleHeight: function () {
        $('[data-js="tile-title"]').each(function () {
            if ($(this).find('[data-js="tile-title-text"]').innerHeight() > $(this).innerHeight()) {
                $(this).addClass('ellipsis');
            } else {
                $(this).removeClass('ellipsis');
            }
        });
    },

    resizeCarousel: function () {
        carouselManager.tileTitleHeight();
        $(window).resize(function () {
            carouselManager.tileTitleHeight();
        });
    }
};

module.exports = carouselManager;
