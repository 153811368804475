'use strict';

var lastScrollTop = 0;
var minScroll = 5;
var headerHeight;
setTimeout(function () {
    headerHeight = $('header').innerHeight();
}, 500);

/**
* show header when scroll up and hide header when scroll down
*/
function headerAnimate() {
    var scrollInit = $(window).scrollTop();
    var isMblMenuVisible = $('#navbarCollapse').is(':visible');

    // Make sure they scroll more than minScroll
    if (Math.abs(lastScrollTop - scrollInit) <= minScroll) return;

    // Scroll Down - If they scrolled down and are past the navbar
    if (!isMblMenuVisible && scrollInit > lastScrollTop && scrollInit > headerHeight) {
        $('body').addClass('header-hide');
        $('header').css('top', headerHeight * -1);
        $('.main-menu').addClass('d-none');
        $('.search-wrapper').addClass('d-none');
    // Scroll Up
    } else if (!isMblMenuVisible && scrollInit + $(window).height() <= $(document).height()) {
        $('body').removeClass('header-hide');
        $('header').removeAttr('style');
        $('.main-menu').removeClass('d-none');
        $('.search-wrapper').removeClass('d-none');
    }
    lastScrollTop = scrollInit;
}

/**
 * Show search layer
 */
function showSearch() {
    if ($('[data-js="menu-group"]').hasClass('show')) {
        $('[data-js="navbar-toggler"]').trigger('click');
    }
    $('[data-js="content-search"]').toggleClass('d-xl-none').toggleClass('search-container-expanded');
    $('[data-js="search-input"]').trigger('focus');
}

/**
 * Open Expanded Search on Mobile
 */
function expandSearchMobile() {
    if (window.checkMq() !== 'xl' && !$('[data-js="content-search"]').hasClass('search-container-expanded')) {
        showSearch();
        // $('body, html').addClass('overflow-y');
    }
}

/**
 * Check Expanded Search on mobile and large screen
 */
function checkSearchMobile() {
    if (window.checkMq() !== 'xl') {
        if ($('.search-title').css('display') === 'block') {
            $('body, html').addClass('overflow-y');
        } else {
            $('body, html').removeClass('overflow-y');
        }
    }
}

/**
 * Settings when header banner is opened. Calculate styles for:
 * - main
 * - menu mobile
 * - navigation my account, view also profile.js
 * - search layer desktop
 * - header compare -> view compare.js
 */
function setOpenHeaderBanner() {
    $('body').addClass('has-banner');
    $('.header-banner').removeClass('hide');
    setTimeout(function () {
        $('main').css('padding-top', headerHeight); // main position
        $('.navbar-collapse, .submenu-level-2').css('top', headerHeight); // menu mobile position
        $('.account-menu-container').css('top', headerHeight + 1); // menu account mobile position
    }, 500);
}

/**
 * Recalc the padding top of the newsletter unsubscribe message,
 * on mobile devices
 * @param {number} headerBannerHeight - The height of the header banner
 * @return {void}
 */
function resizeNewsletterUnsubscribeMsgOnMbl(headerBannerHeight) {
    if (
        $('.header-banner').is(':visible')
        && window.innerWidth <= 599
        && $('.newsletter-unsubscribe-wrapper .content-wrapper').length
    ) {
        const newsletterUnsubscribeMsgPaddingTop = parseFloat($('.newsletter-unsubscribe-wrapper .content-wrapper').css('padding-top'));
        const newPaddingTop = newsletterUnsubscribeMsgPaddingTop - headerBannerHeight;

        $('.newsletter-unsubscribe-wrapper .content-wrapper').css('padding-top', newPaddingTop);
    }
}

/**
 * Settings when header banner is closed. Reset styles for:
 * - main
 * - menu mobile
 * - navigation my account
 * - search layer desktop
 * - header compare
 * - newsletter unsubscribe messsage
 */
function setCloseHeaderBanner() {
    const headerBannerHeight = $('.header-banner').outerHeight(true);
    $('body').removeClass('has-banner');
    $('.header-banner').addClass('hide');
    $('main, .navbar-collapse, .submenu-level-2, .product-header, .account-menu-container, .search-container').removeAttr('style');
    window.sessionStorage.setItem('hide_header_banner', '1');
    resizeNewsletterUnsubscribeMsgOnMbl(headerBannerHeight);
}

/**
 * Check header banner Status (open/close)
 */
function checkHeaderBannerStatus() {
    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    if ((!headerBannerStatus || headerBannerStatus < 0) && $('.header-banner').length > 0) {
        setOpenHeaderBanner();
    }

    $('.header-banner .close').on('click', function () {
        setCloseHeaderBanner();
    });
}

module.exports = function () {
    $(window).scroll(function () {
        headerAnimate();
    });

    $(window).on('resize', function () {
        checkSearchMobile();
    });

    $('[data-js="btn-search"]').click(function () {
        showSearch();
    });

    $('[data-js="search-input"]').on('click focus', function () {
        expandSearchMobile();
    });

    checkHeaderBannerStatus();
};
