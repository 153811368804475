'use strict';

/**
 * Updates the wihslist button status in the Product Detail Page
 *
 * @param {string} wishlistActionHtml - Rendered template of the variant wishlist action to show
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateWishlistButton(wishlistActionHtml, $productContainer) {
    if ($productContainer !== undefined) {
        var wishlistActions = $productContainer.find('[data-js="wishlistActions"]');
        if (wishlistActions.length > 0) {
            $(wishlistActions).html(wishlistActionHtml);
        }
    }
}

module.exports = {
    wishlistActions: function () {
        $(document).on('click', '[data-js="wishlistActions"]', function (e) {
            e.preventDefault();
            var $this = $(this);
            var action = $this.find('.wishlist-pdp-buttom').attr('data-action');

            $.ajax({
                url: action,
                method: 'GET',
                beforeSend: function () {
                    $.spinner().start();
                },
                success: function (data) {
                    if (!data.errorHtml) {
                        $this.html($(data.wishlistActionHtml));
                    }
                },
                complete: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    updateWishlistButton: updateWishlistButton
};
