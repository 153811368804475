'use strict';

/**
 * Returns true if we are in a page part of the travel's flow
 * @returns {boolean} - The result
 */
function isTravelFlow() {
    var travelFlow = false;

    if ($('#footercontent').length > 0) {
        var attrTravelFlow = $('#footercontent').attr('data-is-travel-flow');
        if (typeof attrTravelFlow !== 'undefined' && attrTravelFlow !== false && attrTravelFlow === 'true') {
            travelFlow = true;
        }
    }

    return travelFlow;
}

module.exports = function () {
    $('[data-js="customerType-selector-link"]').on('click', function (e) {
        e.preventDefault();
        var action = $('.page').data('action');
        if (action.match(/Sites-[^-]+-Site/) !== null) {
            action = 'Home-Show';
        }
        var queryString = $('.page').data('querystring');
        if (isTravelFlow() === true) { queryString += '&isTravelFlow=true'; }
        var url = $('[data-js="customerType-selector"]').data('url');
        var siteID = $(this).data('site');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            data: {
                siteID: siteID,
                queryString: queryString,
                action: action
            },
            success: function (response) {
                $.spinner().stop();
                if (response && response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('[data-js="geographical-selector"]').on('change', function (e) {
        e.preventDefault();
        var action = $('.page').data('action');
        if (action.match(/Sites-[^-]+-Site/) !== null) {
            action = 'Home-Show';
        }
        var queryString = $('.page').data('querystring');
        if (isTravelFlow() === true) { queryString += '&isTravelFlow=true'; }
        var url = $('[data-js="geographical-selector"]').data('url');
        var siteID = $(this).val();

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            data: {
                siteID: siteID,
                queryString: queryString,
                action: action
            },
            success: function (response) {
                $.spinner().stop();
                if (response && response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};
