'use strict';

window.$ = require('jquery');
window.jQuery = window.$;

var processInclude = require('./util');

require('./thirdParty/bootstrap');
require('./thirdParty/bootstrap-slider');
require('./thirdParty/moment');
require('./thirdParty/date-range-picker');

jQuery(function () {
    processInclude(require('./utils/utilsXss'));
    processInclude(require('./utils/utilsMq'));
    processInclude(require('./components/menu'));
    processInclude(require('./components/consentTracking'));
    processInclude(require('./components/header'));
    processInclude(require('./components/miniCart'));
    processInclude(require('../../../../../app_storefront_base/cartridge/client/default/js/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('../../../../../app_storefront_base/cartridge/client/default/js/components/clientSideValidation'));
    processInclude(require('./components/languageSelector'));
    processInclude(require('./components/siteChange'));
    processInclude(require('./components/carousel'));
    processInclude(require('./components/seeMoreCollapse'));
    processInclude(require('./components/spinner'));
    processInclude(require('./tealium'));
    processInclude(require('./tags'));
    processInclude(require('./components/newsletter'));
    processInclude(require('../../../../../int_ccf_omnichannel/cartridge/client/default/js/newsletter/newsletter'));
    processInclude(require('../../../../../int_ccf_omnichannel/cartridge/client/default/js/prefixCombobox/prefixCombobox'));
});
