'use strict';

var cart = require('../../../../../../app_storefront_wivai/cartridge/client/default/js/cart/cart');

var updateMiniCart = true;

module.exports = function () {
    cart();

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.add-to-cart-container .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $('.minicart').on('mouseenter focusin touchstart', function () {
        if (!window.isMobile() && $('.checkout-process').length <= 0) {
            var url = $('.minicart').data('action-url');
            var count = parseInt($('.minicart .minicart-quantity').text(), 10);

            if (count !== 0 && $('.minicart .popover.show').length === 0) {
                if (!updateMiniCart && $('.wishlist-page').length === 0) {
                    $('.minicart .popover').addClass('show');
                    return;
                }

                $('.minicart .popover').spinner().start();
                $('.minicart .popover').addClass('show');
                $.get(url, function (data) {
                    $.spinner().stop();
                    $('.minicart .popover').empty();
                    $('.minicart .popover').append(data);
                    updateMiniCart = false;
                });
            }
        }
    });
    $('.minicart').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
        }
    });
    $('.minicart').on('mouseleave focusout', function (event) {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            window.location.reload();
        }
    });
    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });
    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
};
