'use strict';

/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */

/**
 * This file contians common client newsletter logic for both sites wivai and asistido
 */
const CSS_SELECTORS = {
    wrappers: {
        wivaiHomeWrapper: '.page [data-site=wivai] .newsletter-home-banner',
        wivaiLandingWrapper: '[data-querystring*=landing] [data-site=wivai] .newsletter-landing'
    },
    forms: {
        wivaiHomeFormCssSelector: '.page [data-site=wivai] .newsletter-home-banner .js-newsletter-form',
        wivaiLandingFormCssSelector: '[data-querystring*=landing] [data-site=wivai] .js-newsletter-form',
        asistidoLandingFormCssSelector: '[data-querystring*=landing] [data-site=asistido] .js-newsletter-form'
    },
    asistidoAcceptTermsAndConditionsModal: {
        wrapper: '[data-site="asistido"] ~ #newsletter-modal .content-asset:has(.terms-and-conditions-wrapper)',
        downloadBtn: '[data-site="asistido"] ~ #newsletter-modal .js-newsletter-modal-download-btn',
        printBtn: '[data-site="asistido"] ~ #newsletter-modal .js-newsletter-modal-print-btn',
        acceptBtn: '[data-site="asistido"] ~ #newsletter-modal .asistido-accept-button .button-wrapper .btn.btn-primary',
        pdfUrlInput: '[data-site="asistido"] ~ #newsletter-modal [name="newsletter-terms-and-conditions-pdf"]'
    },
    wivaiAcceptTermsAndConditionsModal: {
        acceptAndRejectBtns: '[data-site="wivai"] #newsletter-modal .desktop-buttons.wivai-buttons .btn-accept-or-reject-wrapper button, [data-site="wivai"] #newsletter-modal .mobile-buttons.wivai-buttons .btn-accept-or-reject-wrapper button',
        desktopDownloadBtn: '[data-site="wivai"] #newsletter-modal .desktop-buttons.wivai-buttons .btn-download-wrapper button.link',
        mobileDownloadBtn: '[data-site="wivai"] #newsletter-modal .modal-content .terms-and-conditions-wrapper button.link',
        pdfUrlInput: '[data-site="wivai"] #newsletter-modal [name="newsletter-terms-and-conditions-pdf"]'
    }
};

const MODAL_COMPONENTS = {
    CTA: 'GO_TO_SUBSCRIPTION_FORM',
    TERMS: 'TERMS_AND_CONDITIONS',
    RESULT: 'SUBSCRIPTION_RESULT_FEEDBACK'
};

/**
 * Open the modal
 */
function openModal() {
    $('#newsletter-modal').addClass('opened');
}

/**
 * Close the modal
 */
function closeModal() {
    $('#newsletter-modal').removeClass('opened');
}

/**
 * Check if is Wivai site
 * @returns {bool} return if the site is wivai or not
 */
function isWivaiSite() {
    return $('[data-site="wivai"]').length > 0;
}

/**
 * Check if is Asistido site
 * @returns {bool} return if the site is asistido or not
 */
function isAsistidoSite() {
    return $('[data-site="asistido"]').length > 0;
}

/**
 * Send newsletter form to endpoint
 * @param {Object} $formObj - jQuery object of form
 * @return {Object} response of the request
 */
async function sendNewsletterFormData($formObj) {
    try {
        const endpoint = $formObj.attr('action');
        const method = $formObj.attr('method');
        const res = await fetch(endpoint, {
            method,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: $formObj.serialize()
        });

        if (!res.ok) {
            throw new Error(`Error trying to subscribe, HTTP Error ${res.status}`);
        }

        return await res.json();
    } catch (error) {
        return {
            error: true
        };
    }
}

/**
 * Append a click event to define the KO button behaviour,
 * when the result of subscription was wrong.
 * @param {Object} $KoBtn - KO button DOM node
 */
function initKoBtnBehaviour($KoBtn) {
    $KoBtn.on('click', function (e) {
        e.preventDefault();

        if (isWivaiSite()) {
            const $wivaiSite = $('[data-site="wivai"]');
            const $formWrapper = $wivaiSite.find('.js-newsletter-form');
            const $titleWrapper = $wivaiSite.find('.banner-title-wrapper');
            const $resultWrapper = $formWrapper.siblings('.newsletter-result-wrapper');

            if ($resultWrapper.length) {
                $resultWrapper.remove();

                if ($formWrapper.hasClass('hide')) {
                    $formWrapper.removeClass('hide');
                }

                if ($titleWrapper.hasClass('hide')) {
                    $titleWrapper.removeClass('hide');
                }
            }
        } else if (isAsistidoSite()) {
            const $newsletterModal = $('#newsletter-modal');

            if ($newsletterModal.is(':visible')) {
                closeModal();
            }
        }
    });
}

/**
 * Append a click event to define the OK button behaviour,
 * when the result of subscription was ok. Just for asistido.
 * @param {Object} $OkBtn - OK button DOM node
 */
function initOkBtnBehaviour($OkBtn) {
    if (isAsistidoSite()) {
        $OkBtn.on('click', function (e) {
            e.preventDefault();
            window.location.href = $OkBtn.attr('href');
        });
    }
}

/**
 * The modal is used for three cases:
 * - The CTA to subscribe (wivai)
 * - The terms and conditions (asistido & wivai)
 * - The result of subscription (asistido)
 * This function handles what should be shown
 * @param {string} whatToShow - what to show
 */
function handleWhatToShowInModal(whatToShow) {
    const isWivai = isWivaiSite();
    const isAsistido = isAsistidoSite();
    const isHomePage = $('[data-querystring*=landing]').length === 0;
    const isLandingPage = !isHomePage;
    const $modalElement = $('#newsletter-modal');
    const $ctaModalElement = $modalElement.find('.content-asset:has(.modal-body.row)');
    const $termsModalElement = $modalElement.find('.content-asset:has(.terms-and-conditions-wrapper)');

    if (isWivai && isHomePage && whatToShow === MODAL_COMPONENTS.CTA) {
        $termsModalElement.hide();
        $ctaModalElement.show();
        $modalElement.removeClass('terms-and-conditions');
    } else if (isWivai && (isHomePage || isLandingPage) && whatToShow === MODAL_COMPONENTS.TERMS) {
        $termsModalElement.show();
        $ctaModalElement.hide();
        $modalElement.addClass('terms-and-conditions');
    } else if (isAsistido && isLandingPage && whatToShow === MODAL_COMPONENTS.TERMS) {
        $modalElement.addClass('terms-and-conditions').removeClass('subscription-result');
        $modalElement.find('.newsletter-result-wrapper').remove();
    } else if (isAsistido && isLandingPage && whatToShow === MODAL_COMPONENTS.RESULT) {
        $modalElement.addClass('subscription-result').removeClass('terms-and-conditions');
    }
}

/**
 * This function adjusts the position of newsletter form wrapper
 * to the viewport, to see it properly on the web browser.
 * And hides the header.
 */
function repositionNewsletterWrapper() {
    if (isWivaiSite()) {
        const isHomePage = $('[data-querystring*=landing]').length === 0;
        const cssSelector = isHomePage
            ? CSS_SELECTORS.wrappers.wivaiHomeWrapper
            : CSS_SELECTORS.wrappers.wivaiLandingWrapper;

        if ($(cssSelector).length) {
            document.querySelector(cssSelector)
                .scrollIntoView({ behaviour: 'instant', block: 'start' });

            setTimeout(function () {
                if (!$('body').hasClass('header-hide')) {
                    $('body').addClass('header-hide');
                    $('header').css('top', $('header').innerHeight() * -1);
                }
            }, 200);
        }
    }
}

/**
 * Handle newsletter subscription response
 * @param {Object} res - The subscription response
 * @param {Object} $form - The form jQuery DOM object
 * @return {void}
 */
function handleNewsletterResponse(res, $form) {
    if (res && res.toRender) {
        const {
            wivaiHomeFormCssSelector,
            wivaiLandingFormCssSelector,
            asistidoLandingFormCssSelector
        } = CSS_SELECTORS.forms;

        const $resultHtml = $(res.toRender);
        const $OkBtn = $resultHtml.find('.result-btn.result-btn-ok');
        const $KoBtn = $resultHtml.find('.result-btn.result-btn-ko');
        const isWivaiForm = $form[0] === $(wivaiHomeFormCssSelector)[0] || $form[0] === $(wivaiLandingFormCssSelector)[0];
        const isAsistidoForm = $form[0] === $(asistidoLandingFormCssSelector)[0];
        let $appendToNode = null;

        if (isWivaiForm) {
            const $container = $('[data-site=wivai] .banner-text-and-form');
            const $formWrapper = $container.find('.js-newsletter-form');
            const $titleWrapper = $container.find('.banner-title-wrapper');

            if (!$formWrapper.hasClass('hide')) {
                $formWrapper.addClass('hide');
            }
            if (!$titleWrapper.hasClass('hide')) {
                $titleWrapper.addClass('hide');
            }

            $appendToNode = $container;
        } else if (isAsistidoForm) {
            const $newsletterModal = $('#newsletter-modal');
            const $resultWrapper = $newsletterModal.find('.newsletter-result-wrapper');

            if ($resultWrapper.length) {
                $resultWrapper.remove();
            }

            $appendToNode = $newsletterModal.find('.modal-content');
            if (!$newsletterModal.is(':visible')) {
                openModal();
            }
        }

        if ($appendToNode.length) {
            $resultHtml.appendTo($appendToNode);
            repositionNewsletterWrapper();
        }

        if ($OkBtn.length) {
            initOkBtnBehaviour($OkBtn);
        }

        if ($KoBtn.length) {
            initKoBtnBehaviour($KoBtn);
        }

        handleWhatToShowInModal(MODAL_COMPONENTS.RESULT);
    }
}

/**
 * This function validates the email with the given regex
 *
 * Note: This function is necessary because the email regex
 * provided by SFCC in Chrome gives an error and does not validate
 * the email properly.
 * @param {Object} $emailInput - The email jQuery DOM object
 * @returns {bool} isValid - Returns if the email is valid
 */
function isEmailValid($emailInput) {
    let isValid = false;
    const pattern = new RegExp($emailInput.attr('pattern'));
    const emailVal = $emailInput.val();

    if (pattern && emailVal) {
        isValid = pattern.test(emailVal);
    }

    return isValid;
}

/**
 * Checks if the form inputs are filled in
 * @returns {bool} inputs validation
 */
function areFormInputsFilledIn() {
    const $nameInput = $('.js-newsletter-form input[type=text].form-control');
    const $emailInput = $('.js-newsletter-form input[type=email].form-control');

    return $nameInput.val().length > 0 && $emailInput.val().length > 0;
}

/**
 * Reset the invalid feedback text's of inputs and hide them
 */
function resetInputsErrorMsgs() {
    const $nameInput = $('.js-newsletter-form input[type=text]');
    const $emailInput = $('.js-newsletter-form input[type=email]');
    const $invalidNameFeedback = $nameInput.find('~ .invalid-feedback');
    const $invalidEmailFeedback = $emailInput.find('~ .invalid-feedback');

    $invalidNameFeedback.is(':visible')
        ? $invalidNameFeedback.hide() : null;

    $invalidEmailFeedback.is(':visible')
        ? $invalidEmailFeedback.hide() : null;

    $invalidNameFeedback.text('');
    $invalidEmailFeedback.text('');
}

/**
 * This function handles the validation
 * @param {Object} $formObj - The form jQuery DOM object
 * @returns {void}
 */
function handleValidation($formObj) {
    let isFormValid = true;
    const $nameInput = $formObj.find('input[type=text]');
    const $emailInput = $formObj.find('input[type=email]');
    const $invalidNameFeedback = $nameInput.find('~ .invalid-feedback');
    const $invalidEmailFeedback = $emailInput.find('~ .invalid-feedback');
    let invalidNameMsg = '';
    let invalidEmailMsg = '';

    resetInputsErrorMsgs();

    if ($nameInput.val().length === 0) {
        invalidNameMsg = $nameInput.attr('data-missing-error');
        isFormValid = false;
    }

    if ($emailInput.val().length === 0) {
        invalidEmailMsg = $emailInput.attr('data-missing-error');
        isFormValid = false;
    }

    if (!isEmailValid($emailInput)) {
        invalidEmailMsg = $emailInput.attr('data-pattern-mismatch');
        isFormValid = false;
    }

    if (invalidNameMsg !== '' && !$invalidNameFeedback.is(':visible')) {
        $invalidNameFeedback.text(invalidNameMsg);
        $invalidNameFeedback.show();
        $nameInput.addClass('is-invalid');
    }

    if (invalidEmailMsg !== '' && !$invalidEmailFeedback.is(':visible')) {
        $invalidEmailFeedback.text(invalidEmailMsg);
        $invalidEmailFeedback.show();
        $emailInput.addClass('is-invalid');
    }

    return isFormValid;
}

/**
 * Reset asistido form state to initial, and reset global states
 * @param {Object} newsletterStates - Newsletter global states
 * @returns {void}
 */
function resetAsistidoFormState(newsletterStates) {
    const { asistidoLandingFormCssSelector } = CSS_SELECTORS.forms;
    $(asistidoLandingFormCssSelector).find('.form-control').val('');
    $(asistidoLandingFormCssSelector).find('button[type=submit]').addClass('disabled');
    $(asistidoLandingFormCssSelector).find('.mandatory-read-wrapper .warning-icon').removeClass('hide');
    $(asistidoLandingFormCssSelector).find('.mandatory-read-wrapper .confirmed-icon').addClass('hide');
    // eslint-disable-next-line no-param-reassign
    newsletterStates.isTermsAndConditionsAccepted = false;
    // eslint-disable-next-line no-param-reassign
    newsletterStates.isSubscriptionSubmitted = false;
}

/**
 * Add event listener to the close buttons of modal
 * @param {Object} newsletterStates - Newsletter global states
 * @returns {void}
 */
function addCloseModalButtonsBehaviour(newsletterStates) {
    $('.js-newsletter-modal-close-btn .icon-close, .js-newsletter-modal-close-btn').on('click', function (e) {
        e.preventDefault();
        const $newsletterModal = $(this).closest('#newsletter-modal');

        if ($newsletterModal.is(':visible')) {
            closeModal();

            if (isAsistidoSite() && newsletterStates.isSubscriptionSubmitted) {
                resetAsistidoFormState(newsletterStates);
            }
        }
    });
}

/**
 * Add event listener to handle when the user wants to close
 * the modal if he/she clicks out of modal
 * @param {Object} newsletterStates - Newsletter global states
 * @returns {void}
 */
function addCloseModalWhenUserClicksOutOfItBehaviour(newsletterStates) {
    $('#newsletter-modal').on('click', function (e) {
        e.preventDefault();
        if (e.target === this && $(this).is(':visible')) {
            closeModal();

            if (isAsistidoSite() && newsletterStates.isSubscriptionSubmitted) {
                resetAsistidoFormState(newsletterStates);
            }
        }
    });
}

/**
 * Add event listener to open the terms & conditions modal
 * @returns {void}
 */
function addOpenTermsAndConditionsModalBehaviour() {
    $('.js-newsletter-form .open-terms-and-conditions').on('click', function (e) {
        e.preventDefault();
        handleWhatToShowInModal(MODAL_COMPONENTS.TERMS);
        openModal();
    });
}

/**
 * Add event listeners to open terms & conditions links
 * @returns {void}
 */
function addOpenTermsAndConditionsLinksBehaviour() {
    $('#newsletter-modal .terms-and-conditions-wrapper .link:not(button)').on('click', function (e) {
        e.preventDefault();
        if ($(this).attr('href') !== '') {
            window.open($(this).attr('href'));
        }
    });
}

/**
 * Add event listeners to terms and conditions buttons and modal
 * @param {Object} newsletterStates - Newsletter global states
 * @returns {void}
 */
function addTermsAndConditionsElementsBehaviour(newsletterStates) {
    const { wivaiAcceptTermsAndConditionsModal, asistidoAcceptTermsAndConditionsModal } = CSS_SELECTORS;

    $(`${wivaiAcceptTermsAndConditionsModal.acceptAndRejectBtns},${asistidoAcceptTermsAndConditionsModal.acceptBtn}`).on('click', function (e) {
        e.preventDefault();
        if (newsletterStates.isTermsAndConditionsAccepted && isAsistidoSite()) {
            closeModal();
            return;
        }

        const $form = $('.js-newsletter-form');
        const $submitButton = $form.find('button[type=submit]');
        const $wivaiBtns = $(wivaiAcceptTermsAndConditionsModal.acceptAndRejectBtns);
        const asistidoBtn = $(asistidoAcceptTermsAndConditionsModal.acceptBtn)[0];

        let isWivaiBtn = false;
        $wivaiBtns.each((_, currentBtn) => {
            if (currentBtn === this) isWivaiBtn = true;
        });

        const isAcceptedOnWivai = isWivaiBtn && $(this).hasClass('accept-btn');
        const isRejectedOnWivai = isWivaiBtn && $(this).hasClass('reject-btn');
        const isAcceptedOnAsistido = this === asistidoBtn && !$(this).hasClass('disabled');

        // eslint-disable-next-line no-param-reassign
        newsletterStates.isTermsAndConditionsAccepted = isAcceptedOnWivai || isAcceptedOnAsistido;
        // eslint-disable-next-line no-param-reassign
        if (isRejectedOnWivai) newsletterStates.isTermsAndConditionsAccepted = false;

        closeModal();

        if (isWivaiSite()) {
            const $iconWrapper = $form.find('.pdf-success-icon-wrapper');
            const $pdfIcon = $iconWrapper.find('.icon.icon-pdf');
            const $checkIcon = $iconWrapper.find('.icon.icon-check');

            if (newsletterStates.isTermsAndConditionsAccepted) {
                $iconWrapper.addClass('check');
                $pdfIcon.addClass('hide');
                $checkIcon.removeClass('hide');
            } else {
                $iconWrapper.removeClass('check');
                $pdfIcon.removeClass('hide');
                $checkIcon.addClass('hide');
            }
        }

        if (newsletterStates.isTermsAndConditionsAccepted && isAsistidoSite()) {
            $form.find('.mandatory-read-wrapper .warning-icon').addClass('hide');
            $form.find('.mandatory-read-wrapper .confirmed-icon').removeClass('hide');
        }

        if (newsletterStates.isTermsAndConditionsAccepted && areFormInputsFilledIn()) {
            $submitButton.removeClass('disabled');
        }

        if (!newsletterStates.isTermsAndConditionsAccepted && isWivaiSite()) {
            $submitButton.addClass('disabled');
        }
    });

    if (isAsistidoSite()) {
        $(asistidoAcceptTermsAndConditionsModal.wrapper).on('scroll', function (e) {
            e.preventDefault();
            if (
                !$(asistidoAcceptTermsAndConditionsModal.acceptBtn).hasClass('disabled')
                || newsletterStates.isTermsAndConditionsAccepted
            ) {
                return;
            }

            const errorMargin = 3; // We'll set a margin of error of 3px to avoid bugs in some screens/user-agents
            const termsAndConditionsWrapperHeight = Math.floor(this.getBoundingClientRect().height);
            const whereIsTheScroll = this.scrollHeight - this.scrollTop;
            const isItWithinTheMargin = ((termsAndConditionsWrapperHeight - errorMargin) >= whereIsTheScroll) || (whereIsTheScroll <= (termsAndConditionsWrapperHeight + errorMargin));

            if (isItWithinTheMargin) {
                $(asistidoAcceptTermsAndConditionsModal.acceptBtn).removeClass('disabled');
            }
        });

        $(`${asistidoAcceptTermsAndConditionsModal.downloadBtn},${asistidoAcceptTermsAndConditionsModal.printBtn}`).on('click', function (e) {
            e.preventDefault();

            try {
                const { downloadBtn, printBtn, pdfUrlInput } = asistidoAcceptTermsAndConditionsModal;
                const pdfUrlAsObj = new URL($(pdfUrlInput).val());
                const pdfUrlAsStr = pdfUrlAsObj.toString();

                if (pdfUrlAsStr && pdfUrlAsStr.startsWith('https') && pdfUrlAsStr.endsWith('.pdf')) {
                    if (this === $(downloadBtn)[0]) {
                        window.open(pdfUrlAsStr, '_blank');
                    } else if (this === $(printBtn)[0]) {
                        window.open(pdfUrlAsStr).print();
                    }
                }
            } catch (error) {
                throw new Error(e.name + ': ' + e.message);
            }
        });
    } else if (isWivaiSite()) {
        $(`${wivaiAcceptTermsAndConditionsModal.desktopDownloadBtn},${wivaiAcceptTermsAndConditionsModal.mobileDownloadBtn}`).on('click', function (e) {
            e.preventDefault();

            try {
                const { desktopDownloadBtn, mobileDownloadBtn, pdfUrlInput } = wivaiAcceptTermsAndConditionsModal;
                const pdfUrlAsObj = new URL($(pdfUrlInput).val());
                const pdfUrlAsStr = pdfUrlAsObj.toString();

                if (pdfUrlAsStr && pdfUrlAsStr.startsWith('https') && pdfUrlAsStr.endsWith('.pdf')) {
                    if (this === $(desktopDownloadBtn)[0] || this === $(mobileDownloadBtn)[0]) {
                        window.open(pdfUrlAsStr, '_blank');
                    }
                }
            } catch (error) {
                throw new Error(e.name + ': ' + e.message);
            }
        });
    }
}

/**
 * Add event listeners to forms of Wivai & Asistido
 * @param {Object} newsletterStates - Newsletter global states
 * @returns {void}
 */
function addFormsBehaviour(newsletterStates) {
    Object.values(CSS_SELECTORS.forms).forEach(formCssSelector => {
        if ($(formCssSelector).length) {
            $(formCssSelector).on('submit', async function (e) {
                e.preventDefault();
                const $formObj = $(this);
                const isValid = handleValidation($formObj);

                if (isValid) {
                    const res = await sendNewsletterFormData($formObj, newsletterStates);
                    // eslint-disable-next-line no-param-reassign
                    newsletterStates.isSubscriptionSubmitted = true;
                    handleNewsletterResponse(res, $formObj);
                }
            });

            // This checks for the email input cuz on chrome the email regex on clientSideValidation.js fails
            $(`${formCssSelector} input[type=text], ${formCssSelector} input[type=email]`).on('invalid', function (e) {
                e.preventDefault();
                const $that = $(this);
                if ($that.attr('type') !== 'email') return;

                let invalidMsg = '';
                if (!isEmailValid($that)) {
                    invalidMsg = $that.attr('data-pattern-mismatch');
                }

                if ($that.val().length === 0) {
                    invalidMsg = $that.attr('data-missing-error');
                }

                $that.find('~ .invalid-feedback').text(invalidMsg);
            });

            $(`${formCssSelector} input[type=text], ${formCssSelector} input[type=email]`).on('keyup', function (e) {
                e.preventDefault();
                const $that = $(this);
                const $form = $that.closest('.js-newsletter-form');
                const $nameInput = $form.find('input[type=text]');
                const $emailInput = $form.find('input[type=email]');
                const $submitButton = $form.find('button[type=submit]');

                if ($nameInput.val().length > 0 && $emailInput.val().length > 0 && newsletterStates.isTermsAndConditionsAccepted) {
                    $submitButton.removeClass('disabled');
                } else if ($nameInput.val().length === 0 && $emailInput.val().length === 0) {
                    $submitButton.addClass('disabled');
                }
            });
        }
    });
}

$(document).ready(function () {
    const newsletterStates = {
        isTermsAndConditionsAccepted: false,
        isSubscriptionSubmitted: false
    };

    addCloseModalButtonsBehaviour(newsletterStates);

    addCloseModalWhenUserClicksOutOfItBehaviour(newsletterStates);

    addOpenTermsAndConditionsModalBehaviour();

    addOpenTermsAndConditionsLinksBehaviour();

    addTermsAndConditionsElementsBehaviour(newsletterStates);

    addFormsBehaviour(newsletterStates);

    handleWhatToShowInModal(MODAL_COMPONENTS.CTA);
});
