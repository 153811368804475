'use strict';

/**
 * Follow you or fixed the price box.
 * @param {element} $fixedElement - Element fixed.
 * @param {element} $maxPositionElement - Element with max position.
 */
function checkOffset($fixedElement, $maxPositionElement) {
    var maxPosition = ($('header').height() + $('.cart-wishlist-selector-container').outerHeight() + $maxPositionElement.outerHeight());
    var elementPosition;

    if ($fixedElement.length > 0) {
        elementPosition = $fixedElement.offset().top;
    } else {
        return;
    }

    if ($fixedElement.hasClass('fixed') && (elementPosition + $fixedElement.outerHeight()) > (maxPosition)) {
        $fixedElement.removeClass('fixed');
        $fixedElement.addClass('fixed-removed');
        // remove fixed when get over maxPosition
    } else if (!$fixedElement.hasClass('fixed')
        && ($(document).scrollTop() < elementPosition)
        && (elementPosition - $(document).scrollTop()) > 220) {
        $fixedElement.addClass('fixed');
        $fixedElement.removeClass('fixed-removed');
        // restore when you scroll up
    }
}

module.exports = {
    positionBox: function ($fixedElement, $maxPositionElement) {
        $(document).on('scroll', function () {
            if (!window.isMobile()) {
                checkOffset($fixedElement, $maxPositionElement);
            }
        });
    }
};
