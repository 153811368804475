'use strict';

window.checkMq = function () {
    return $('[data-js="check-mq"]').css('content').replace(/"/g, '');
};

window.isMobile = function () {
    if ($('[data-js="check-mq"]').is(':visible')) {
        return false;
    }
    return true;
};
