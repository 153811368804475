'use strict';

/**
 * Search into cookies if the given cookie is already created
 * @param {Array} cookiesArray - Array with cookies
 * @param {string} cookieName - The name of the searched cookie
 * @returns {boolean} result of the search
 */
function isCookieAlreadySet(cookiesArray, cookieName) {
    return cookiesArray.some(cookie => {
        return cookie.startsWith(cookieName);
    });
}

/**
 * Get the value of the given cookie
 * @param {Array} cookiesArray - Array with cookies
 * @param {string} cookieName - The name of the searched cookie
 * @returns {string} the value of the cookie
 */
function getCookieValue(cookiesArray, cookieName) {
    return cookiesArray.filter(cookie => {
        return cookie.startsWith(cookieName);
    })[0].split('=')[1];
}

module.exports = {
    isCookieAlreadySet,
    getCookieValue
};
