'use strict';

module.exports = function () {
    $('[data-js="language-selector-link"]').on('click', function (e) {
        e.preventDefault();
        var action = $('.page').data('action');
        if (action.match(/Sites-[^-]+-Site/) !== null) {
            action = 'Home-Show';
        }
        var localeCode = $(this).data('locale');
        var localeCurrencyCode = $(this).data('currencycode');
        var queryString = $('.page').attr('data-querystring');
        var url = $('[data-js="language-selector"]').data('url');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            data: {
                code: localeCode,
                queryString: queryString,
                CurrencyCode: localeCurrencyCode,
                action: action
            },
            success: function (response) {
                $.spinner().stop();
                if (response && response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};
