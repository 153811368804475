'use strict';

var xss = window.location.href.match(/[^\w_-]*$/);

if (xss != null) {
    window.location.hash = window.location.hash.replace(/[^\w]/g, '');
}

window.addEventListener('hashchange', function () {
    if (window.location.hash) {
        window.location.hash = window.location.hash.replace(/[^\w]/g, '');
    }
});
