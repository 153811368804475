'use strict';

var base = require('../product/base');
var focusHelper = require('../components/focus');
var createErrorNotification = require('../components/errorNotification');
var priceBox = require('../components/priceBox');

const LOAN_PAY = '05';
const SUSCRIPTION_PAY = '09';

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            createErrorNotification($('.cart-error'), data.valid.message);
        } else {
            $('.cart').empty().append('<div class="row"> '
                + '<div class="col-12 text-center"> '
                + '<h1>' + data.resources.emptyCartMsg + '</h1> '
                + '</div> '
                + '</div>');
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    var $container = $('.product-summary, .cart-page');

    $container.find('.number-of-items').empty().append(data.resources.numberOfItems);
    $container.find('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $container.find('.tax-total').empty().append(data.totals.totalTax);
    $container.find('.grand-total').empty().append(data.totals.grandTotal);

    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $container.find('.price-box .strike').empty().append(data.itemTotal);
    } else {
        $container.find('.price-box .strike').empty();
    }

    $container.find('.price-box .price-num .value').empty().append(data.totals.subTotalDiscounted);
    $container.find('.sub-total').empty().append(data.totals.subTotalDiscounted);
    $('.minicart-quantity').empty().append(data.numItems);
    $container.find('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $container.find('.order-discount').removeClass('d-none');
        $container.find('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $container.find('.order-discount').addClass('d-none');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $container.find('.shipping-discount').removeClass('d-none');
        $container.find('.shipping-discount-total').empty().append('- '
            + data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $container.find('.shipping-discount').addClass('d-none');
    }
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="c-blue py-2">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Re-renders the main discount messages
 * @param {Object} totals - updated approaching discounts for the cart
 */
function updateTotalDiscount(totals) {
    var $element = $('.product-discount');
    if (totals.orderLevelDiscountTotal.value > 0) {
        $element.removeClass('d-none');
        $element.find('.varDiscount').text(totals.orderLevelDiscountTotal.formatted);
    } else {
        $element.addClass('d-none');
    }
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    // eslint-disable-next-line no-plusplus
    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    $('.availability-' + lineItem.UUID).empty();

    if (lineItem.availability) {
        if (lineItem.availability.messages) {
            lineItem.availability.messages.forEach(function (message) {
                messages += '<p class="line-item-attributes">' + message + '</p>';
            });
        }

        if (lineItem.availability.inStockDate) {
            messages += '<p class="line-item-attributes line-item-instock-date">'
                + lineItem.availability.inStockDate
                + '</p>';
        }
    }

    $('.availability-' + lineItem.UUID).html(messages);
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) {
    // eslint-disable-next-line no-plusplus
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    var lineItem = findItem(data.cartModel.items, function (item) {
        return item.UUID === uuid;
    });

    if (lineItem.variationAttributes) {
        var colorAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === 'color';
        });

        if (colorAttr) {
            var colorSelector = '.Color-' + uuid;
            var newColor = 'Color: ' + colorAttr.displayValue;
            $(colorSelector).text(newColor);
        }

        var sizeAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === 'size';
        });

        if (sizeAttr) {
            var sizeSelector = '.Size-' + uuid;
            var newSize = 'Size: ' + sizeAttr.displayValue;
            $(sizeSelector).text(newSize);
        }

        var imageSelector = '.card.product-info.uuid-' + uuid + ' .item-image > img';
        $(imageSelector).attr('src', lineItem.images.small[0].url);
        $(imageSelector).attr('alt', lineItem.images.small[0].alt);
        $(imageSelector).attr('title', lineItem.images.small[0].title);
    }

    if (lineItem.options && lineItem.options.length) {
        var option = lineItem.options[0];
        var optSelector = '.lineItem-options-values[data-option-id="' + option.optionId + '"]';
        $(optSelector).data('value-id', option.selectedValueId);
        $(optSelector + ' .line-item-attributes').text(option.displayName);
    }

    var qtySelector = '.quantity[data-uuid="' + uuid + '"]';
    $(qtySelector).val(lineItem.quantity);
    $(qtySelector).data('pid', data.newProductId);

    $('.remove-product[data-uuid="' + uuid + '"]').data('pid', data.newProductId);

    var priceSelector = '.line-item-price-' + uuid + ' .sales .value';
    $(priceSelector).text(lineItem.price.sales.formatted);
    $(priceSelector).attr('content', lineItem.price.sales.decimalPrice);

    if (lineItem.price.list) {
        var listPriceSelector = '.line-item-price-' + uuid + ' .list .value';
        $(listPriceSelector).text(lineItem.price.list.formatted);
        $(listPriceSelector).attr('content', lineItem.price.list.decimalPrice);
    }
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $('body').trigger('editproductmodal:ready');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Update promotions html from ajax response
 * @param {Object} data - AJAX response from the server
 */
function updatePromotionsHtml(data) {
    // update summary discounts
    $('[data-js="cart-discount-codes"]').html(data.totals.discountsHtml);
    $('[data-js="cart-promotion-display"]').html(data.totals.promotionsHtml);

    data.items.forEach(function (item) {
        // update product promotions
        if (item.renderedPromotions) {
            $('.uuid-' + item.UUID).find('[data-js="product-promotion"]').empty().append(item.renderedPromotions);
        } else {
            $('.uuid-' + item.UUID).find('[data-js="product-promotion"]').empty();
        }

        // update product prices
        $('.strike-' + item.UUID).html(item.priceTotal.nonAdjustedPrice);
        $('.item-total-' + item.UUID).html(item.priceTotal.price);

        // Hide/Show promotions drop down depending on there are promotions
        if (data.totals.orderLevelDiscountTotal.value > 0 || item.renderedPromotions) {
            $('[data-js="collapsible-discount"]').removeClass('d-none');
        } else {
            $('[data-js="collapsible-discount"]').addClass('d-none');
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $productToRemoveSpan.empty().append(productName);
}

module.exports = function () {
    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.minicart-remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        confirmDelete(actionUrl, productID, productName, uuid);
        $('#minicartRemoveProductModal').modal('show');
        $('.modal-backdrop').css('display', 'none');
        $('#minicartRemoveProductModal').css('background', 'rgba(0,0,0,0.5)');
    });
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        confirmDelete(actionUrl, productID, productName, uuid);
        $('#removeProductModal').modal('show');
        $('.modal-backdrop').css('display', 'none');
        $('#removeProductModal').css('background', 'rgba(0,0,0,0.5)');
    });

    $('body').on('click', '.cart-delete-replace-confirmation-button', function (e) {
        e.preventDefault();
        var productID = $(this).attr('pid');
        var url = $(this).attr('action');
        var uuid = $(this).attr('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function () {
                $('.minicart-quantity').text('0');
                let btnClasses = '.' + $('.cart-and-ipay').data('class-ref').replaceAll(' ', '.');
                $(btnClasses).trigger('click');
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.items.length === 0) {
                    $('.carti')
                        .empty()
                        .append(
                            '<div class="row ca_cart_box ca_cart_product_title">'
                            + '<div class="container cart-empty">'
                            + '<div class="row justify-content-center">'
                            + ' <div class="col-8 text-center">'
                            + '   <p class="ca_cart_empty_title">' + data.text.content.title + '</p>'
                            + '   <p class="ca_cart_empty_subtitle">' + data.text.content.subtitle + '</p>'
                            + '     <button class="btn btn-block ca_btn_primary">'
                            + '       <a href="' + data.text.urlHome + '" class="ca_link_buttom d-block"'
                            + '       role="menuitem" tabindex="-1">' + data.text.content.buttom
                            + '       </a>'
                            + '     </button>'
                            + '    </div>'
                            + ' </div>'
                            + '</div>'
                            + '</div>'
                        );

                    $('.empty-delete')
                        .remove();

                    $('.number-of-items')
                        .empty()
                        .append(data.basket.resources.numberOfItems);
                    $('.minicart-quantity')
                        .empty()
                        .append(data.basket.numItems);
                    if (data.basket.numItems === 0) {
                        $('.minicart-quantity').hide();
                    }
                    $('.minicart-link').attr({
                        'aria-label':
                            data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                    // eslint-disable-next-line no-restricted-globals
                    location.reload(true);
                } else {
                    if (
                        data.toBeDeletedUUIDs
                        && data.toBeDeletedUUIDs.length > 0
                    ) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i += 1) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    $('.uuid-' + uuid).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    $('.coupons-and-promos')
                        .empty()
                        .append(data.basket.totals.discountsHtml);
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(
                        data.basket.approachingDiscounts
                    );
                    $('body').trigger(
                        'setShippingMethodSelection',
                        data.basket
                    );
                    validateBasket(data.basket);
                }

                $('body').trigger('cart:update');
                location.reload();
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    // $('body').on('click', '.remove-product, .minicart-remove-product', function (e) {
    //     e.preventDefault();

    //     var productID = $(this).data('pid');
    //     var url = $(this).data('action');
    //     var uuid = $(this).data('uuid');
    //     var urlParams = {
    //         pid: productID,
    //         uuid: uuid
    //     };

    //     url = appendToUrl(url, urlParams);

    //     $.spinner().start();
    //     $.ajax({
    //         url: url,
    //         type: 'get',
    //         dataType: 'json',
    //         // Always reload page. If not, TODO: update buttons for selecting prices and financed prices
    //         // TODO: Update products and complements from redirect link to 'CaixaBank Now'
    //         // (when is loan payment) - [data-js="btn-now-redirect"]
    //         /*
    //         success: function (data) {
    //             if (data.basket.items.length === 0) {
    //                 $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
    //                 $('.minicart-quantity').empty().append(data.basket.numItems);
    //                 $('.minicart-link').attr({
    //                     'aria-label': data.basket.resources.minicartCountOfItems,
    //                     title: data.basket.resources.minicartCountOfItems
    //                 });
    //                 $('.minicart .popover').empty();
    //                 $('.minicart .popover').removeClass('show');
    //                 $('html').removeClass('veiled');
    //             } else {
    //                 if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
    //                     // eslint-disable-next-line no-plusplus
    //                     for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
    //                         $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
    //                     }
    //                 }
    //                 // check if exists more children items to remove 'Optional Complements' title
    //                 if ($('.uuid-' + uuid).hasClass('set-line-item') && $('.set-line-item').length === 1) {
    //                     $('.set-line-item-container').remove();
    //                 }
    //                 $('.uuid-' + uuid).remove();

    //                 if (!data.basket.hasBonusProduct) {
    //                     $('.bonus-product').remove();
    //                 }
    //                 updateCartTotals(data.basket);
    //                 removeApproachingDiscounts(data.basket.totals);
    //                 $('body').trigger('setShippingMethodSelection', data.basket);
    //                 validateBasket(data.basket);
    //             }

    //             $('body').trigger('cart:update');

    //             // Only reload the page when it is at cart page
    //             if ($('.cart-page') && data.basket.items.length === 0) {
    //                 window.location.reload();
    //             } else {
    //                 $.spinner().stop();
    //             }
    //         },
    //         */
    //         success: function () {
    //             $('body').trigger('cart:update');
    //             window.location.reload();
    //         },
    //         error: function (err) {
    //             if (err.responseJSON.redirectUrl) {
    //                 window.location.href = err.responseJSON.redirectUrl;
    //             } else {
    //                 createErrorNotification($('.cart-error'), err.responseJSON.errorMessage);
    //                 $.spinner().stop();
    //             }
    //         }
    //     });
    // });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };

        $('.totals').spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification($('.cart-error'), err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('#promo-code-form').submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        var $form = $('#promo-code-form');
        $('.coupon-missing-error').addClass('d-none');
        $('.coupon-error-message').empty().addClass('d-none');
        if (!$('.coupon-code-field').val()) {
            $form.find('.form-control').addClass('is-invalid');
            $form.find('.form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').removeClass('d-none');
            $.spinner().stop();
            return false;
        }
        $form.find('.form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty().addClass('d-none');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $form.find('.form-control').addClass('is-invalid');
                    $form.find('.form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage).removeClass('d-none');
                    $('body').trigger('promotion:error', data);
                } else {
                    updateCartTotals(data);
                    updateTotalDiscount(data.totals);
                    updateApproachingDiscounts(data.totals);
                    validateBasket(data);
                    updatePromotionsHtml(data);
                    $('body').trigger('promotion:success', data);
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification($('.cart-error'), err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('.coupon-error-message, .coupon-missing-error').empty().addClass('d-none');
        $('#promo-code-form').find('.form-control').removeClass('is-invalid');
        $('#promo-code-form').find('.form-control').removeAttr('aria-describedby');

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.reloadPage === true) {
                    $.spinner().stop();
                    window.location.reload();
                } else {
                    $('.coupon-uuid-' + uuid).remove();
                    updateCartTotals(data);
                    updateTotalDiscount(data.totals);
                    updateApproachingDiscounts(data.totals);
                    validateBasket(data);
                    updatePromotionsHtml(data);
                    $('body').trigger('promotion:success', data);
                    $.spinner().stop();
                }
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification($('.cart-error'), err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr('disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available'));
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        // Discard updates for the entire bundle product
        if (response.product.productType !== 'bundle') {
            $('.product-availability', response.$productContainer)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }

        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        $(this).parents('.card').spinner().start();
        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    validateBasket(data.cartModel);

                    $('body').trigger('cart:update');

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification($('.cart-error'), err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $(document).on('click', '[data-js="btn-payment-cart"]', function () {
        var paymentMethod = $(this).data('payment-method');
        if (paymentMethod !== LOAN_PAY && paymentMethod !== SUSCRIPTION_PAY) {
            $.spinner().start();
            var $form = $('[data-js="cart-change-payment"]');
            var url = $form.attr('data-action');
            if ($(this).find('[data-quota]').length > 0) {
                var $info = $(this).find('[data-js="total-price-quota"]');
                var paymentObj = {
                    quotas: parseInt($info.attr('data-quota'), 10),
                    tin: parseFloat($info.attr('data-tin').replace(",", ".")/100),
                    tae: parseFloat($info.attr('data-tae').replace(",", ".")/100),
                    financingType: $info.attr('data-financingtype')
                };
                $form.find('input[name="newPaymentInfo"]').val(JSON.stringify(paymentObj));
            } else {
                $form.find('input[name="newPaymentInfo"]').val('');
            }
            $form.find('input[name="newPaymentMethod"]').val($(this).attr('data-payment-method'));

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: $form.serialize(),
                success: function (data) {
                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }
                    $.spinner().stop();
                }
            });
        }
        return false;
    });

    priceBox.positionBox($('.cart-body').find('.price-box'), $('.cart-page .container'));

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
};
